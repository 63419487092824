import {useEffect, useState, useQuery} from 'react';
import { useNavigate } from "react-router-dom"
import api from '../contexts/BaseUrl';
import './Styles/Home.css';
import Loading from '../components/Loading';

import CustomButton from '../components/CustomButton';


function HRStaffHome() {

    const navigate = useNavigate();

    const [allData, setAllData] = useState([]);
    const [pagination, setPagination] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);

   


    const [page, setPage] = useState(1);
    
    const changeParam = () => {
        if(pagination.totalPages>pagination.currentPage){
            setPage(page + 1);
        }
        // navigate({
        //     pathname:'/hr-staff',
        //     search: createSearchParams({
        //         pageNumber: page
        //     }).toString()
        // });

    };

    const changeParamBack = () => {
        if(page>1){
            setPage(page-1);
        }
    };

    const handleChange = (event) => {      
        if(event.target.value > pagination.totalPages){
            var num = pagination.totalPages;
            setPage(num);
        }
        else{
            setPage(event.target.value)
        }
    }

  
  var params;

  if(searchInput.length > 0){
    params = new URLSearchParams({
        checkUser: searchInput
      }).toString();
  }else{
    params = new URLSearchParams({
        pageNumber: page,
      }).toString();
  }
   
    const url = "api/workers?" + params;
    // const getWorkerInfo = async () => {
    //     const {data} = await api.get(url)
    //     return data;
    // }

    // const {data} = useQuery("userInfo", getWorkerInfo);

    useEffect(() => {
        setIsLoading(true);
        api.get(url)
        .then(response => {
            setAllData(response.data);
            setIsLoading(false)
            setPagination(JSON.parse(response.headers.pagination));
        }).catch(err => {
            console.log("Getting data failed");
            setIsLoading(false);
            },
        )}, [url]);

    

    return ( 
        
        <div>  
            <div className='leftBar'>
               
                <CustomButton
                    title='Home'
                    onClick={() => navigate('/hr-staff')}
                />
            </div>
            <div className='rightBar'>
                    <CustomButton
                        title='Add Employee'
                        onClick={() => navigate('/hr-staff/add-worker')}
                    />
                    
                                
            </div>
            
            <div className="workers">
                <div className="workersContent">
                        <input 
                            type="text"
                            className='searchBar'
                            value = {searchInput}
                            placeholder="Search by Employee ID or Employee Name"
                            onChange={(event) => setSearchInput(event.target.value.toLowerCase()) } 
                        />
                  {isLoading ? <Loading /> :  
                    <table>
                        <thead>
                        <tr>
                            <th>Employee Id</th>
                            <th>Name</th>
                            <th>Phone Number</th>
                            <th>Joining Date</th>
                            <th>Department</th>
                            <th>Details</th>
                        </tr>
                        </thead>
                        
             
                    {allData.map ((a, key) => {
                        return (
                        <tbody key={key}>
                        <tr>
                                <td>{a.userId}</td>
                                <td>{a.name}</td>
                                <td>{a.phoneNumber}</td>
                                <td>{a.dateOfJoining}</td>
                                <td>{a.department}</td>
                                <td><button onClick={() => navigate(`/hr-staff/worker/${a.userId}`)}>View Details</button></td>
                        </tr>
                        </tbody>
                        )
                    })}
                

                    </table>}
                    <div className='changePageFooter'>
                        <button id='backButton' onClick={changeParamBack}>Back</button>
                        <input
                            id='inputFooter'
                            value={page}
                            onChange={handleChange}
                        />
                        <span id='totalPage'> / {pagination.totalPages}</span>
                        <button id='nextButton' onClick={changeParam}>Next</button>
                    </div>
                </div>         
            </div>
        </div>
        
     );
}
 
export default HRStaffHome;

