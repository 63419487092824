import {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import api from '../../contexts/BaseUrl';
import Loader from '../../components/Loader';
import {useQuery} from 'react-query';



function ReceivedData(){

    const navigate = useNavigate();

    let params = useParams();
    var articleItemId = params.id;

    const [dateValue, setDateValue] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [container, setContainer] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [err, setErrMsg] = useState('');


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try{
            const response = await api.post(`/api/receiveddates/create-receiveddate?Date=${dateValue}&Quantity=${quantity}&articleItemId=${articleItemId}&Container=${container}`,
            {
                headers : {
                    'Content-Type' : 'application/json'
                }
            })

            setDateValue('');
            setQuantity('');
            setSuccess(true);
            setIsLoading(false);
            // setShowArticleButton(true)
            navigate('update');
        }catch(err){
            setErrMsg('Failed adding received data!')
        }
    }


    return(
        
        <div>       
            <div className='heading'> Add Received Date</div>
                
            <form className='login' onSubmit={handleSubmit}>
            <label>Received Date</label>
            <input 
                type='date'
                required
                value={dateValue}
                onChange={(event) => setDateValue(event.target.value) }
            />
            <label>Received Container</label>
            <input 
                type='text'
                required
                value={container}
                onChange={(event) => setContainer(event.target.value) }
            />
            <label>Received Quantity</label>
            <input 
                type='number'
                required
                value={quantity}
                onChange={(event) => setQuantity(event.target.value) }
            />
            {isLoading ? <Loader /> : <button id='addButton'>Save</button>}
            {!success && <div className='error'>{err} </div>} 
            {success && <div className='success'>Received Data Added Successfully!</div>}
            </form>         
        </div>
        
    )
}

export default ReceivedData;