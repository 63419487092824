import {useState, useEffect} from 'react';
import {useQuery} from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import '../Styles/RosterDetails.css';
import api from '../../contexts/BaseUrl';
import CustomButton from '../../components/CustomButton';
import Loading from '../../components/Loading';

function Worker () {

    let params = useParams();

    var workerId = params.userId;

    const getWorkerInfo = async () => {
        const {data} = await api.get(`/api/workers/get-worker/${workerId}`)
        return data;
    }
    const {data} = useQuery("userInfo", getWorkerInfo);
    let worker = data;

    const [searchDate, setSearchDate] = useState('');

    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

   // const [allRosters] = useState(worker.rosters);

   const changeParam = () => {
       if(pagination.totalPages > pagination.currentPage){
            setPage(page+1);
       }    
    };
   const changeParamBack = () => {
        if(page>1){
            setPage(page-1);
        }
    };

    const handleChange = (event) => {
        if(event.target.value > pagination.totalPages){
            var num = pagination.totalPages;
            setPage(num);
        }else{
            setPage(event.target.value)
        }   
        
    }

    const [rosters, setRoster] = useState([]);

    var urlsParams;

    if(searchDate.length > 0){
        urlsParams = new URLSearchParams({
            WorkerId: params.userId,
            Date: searchDate
        }).toString();
    }else{
        urlsParams = new URLSearchParams({
            WorkerId: params.userId,
            PageNumber: page
        }).toString();
    }

    const url = "api/rosters/rosters-worker?" + urlsParams;

    useEffect(() => {
        setIsLoading(true);
        api.get(url)
        .then(response => {
            setRoster(response.data);
            setIsLoading(false);
            setPagination(JSON.parse(response.headers.pagination));
        }).catch(err => {
            console.log("Getting data failed");
            setIsLoading(false);
            },
        )}, [url]);

 
    return ( 
        <div> 
            <div className='leftBar'>
                <CustomButton
                    title='Home'
                    onClick={() => navigate('/hr-staff')}
                />
                
            </div>
            <div className='rightBar'>
                    <CustomButton
                        title='Add New Roster'
                        onClick={() => navigate('add')}
                    />
                                   
            </div>
            
            <div className='heading'>Roster Details</div>
            <div className="heading2">{worker.name} - {worker.userId} - {worker.department}</div> 
            <div className='heading'>
                <input 
                    type='date' 
                    className='inputDate'
                    value={searchDate}
                    onChange={(event) => setSearchDate(event.target.value)}
                />
            </div>
            <div className='rosterTable'>
            {isLoading ? <Loading /> :
            <table id='rosterTable'>
                <thead>
                   
                    <tr className='workerBody'>
                        <th>Date</th>
                        <th>Shift Start</th>
                        <th>Shift Ends</th>
                        <th>Details</th>
                    </tr>
                </thead>
                {rosters.map ((a, key) => {
                        return (
                        <tbody key={key} >
                        <tr>
                                <td>{a.date}</td>
                                <td>{a.shiftFrom}</td>
                                <td>{a.shiftTo}</td>
                                <td><button onClick={() => navigate(`${a.id}`)}>Edit</button></td>
                                
                        </tr>
                        </tbody>
                        )
                    })}
            </table>}
            </div>
            <div className='changePage'>
                        <button id='backButton' onClick={changeParamBack}>Back</button>
                        <input
                            id='inputFooter'
                            value={page}
                            onChange={handleChange}
                        />
                        <span id='totalPage'> / {pagination.totalPages}</span>
                        <button id='nextButton' onClick={changeParam}>Next</button>
            </div>
           
           
        </div>
        
     );
}
 
export default Worker;
