import {CSSProperties} from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import './Styles/Loader.css';

export default function Loader(props){

    const override: CSSProperties = {
        background: "rgb(78, 145, 233)"
      };

    return(
        <div className='spinner'>
                <ClipLoader color='white' size={25} cssOverride={override} />
        </div>
    )
}