import {useState} from 'react';
import {useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import api from '../../contexts/BaseUrl';
import Loader from '../../components/Loader';
import '../Styles/AddWorker.css';

const AddMonthlyPayroll = () => {

    let params = useParams();

    // let worker = GetDataWorkers(params.userId);

    var workerId = params.userId;

    // const getWorkerInfo = async () => {
    //     const {data} = await api.get(`/api/workers/get-worker/${workerId}`)
    //     return data;
    // }
    // const {data} = useQuery("userInfo", getWorkerInfo);
    // let worker = data;
   
   
    
    const [fromTimePeriod, setFromTimePeriod] = useState('');
    const [toTimePeriod, setToTimePeriod] = useState('');
   

    const [success, setSuccess] = useState(null);
    const [err, setErrMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

   
    var fromYear = parseInt(fromTimePeriod.substring(0,4));
    var fromMonth = parseInt(fromTimePeriod.substring(5,7));
    var fromDay = parseInt(fromTimePeriod.substring(8));

    var toYear = parseInt(toTimePeriod.substring(0,4));
    var toMonth = parseInt(toTimePeriod.substring(5,7));
    var toDay = parseInt(toTimePeriod.substring(8));
    const handleSubmit =  async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try{
            
            const response = await api.post(`api/payrollsmonthly/create-monthly-payroll?fromDay=${fromDay}&fromMonth=${fromMonth}&fromYear=${fromYear}&toDay=${toDay}&toMonth=${toMonth}&toYear=${toYear}` ,
                {
                },
                {
                    headers: {
                        "Content-Type" : "application/json"
                    }
                });   
            setFromTimePeriod('');
            setSuccess(true);
            setIsLoading(false);
            navigate(-2);
            
            
        }catch(err){

            // setUserId('');
            // setName('');
            // setPhoneNumber('');
            // setDateOfJoining('');
            // setDepartment('');
            setSuccess(false);
            setIsLoading(false);
            if(!err?.response){
                setErrMsg('No Server Response')
            }else{
                setErrMsg('Failed adding worker!');
            }
        }
    }

    return ( 
        
        <div>
            <div className='heading'> Add New Monthly Payroll</div>
            {/* <div className="heading2">{worker.name} - {worker.userId} - {worker.department}</div> */}
            <form className='addWorkerTable' onSubmit={handleSubmit}>
            <table id='workerTable'>
                
                <tbody>
                   
                    <tr>
                        <td>From*</td>
                        <td>
                            <input  
                                type='date'
                                required
                                value={fromTimePeriod}
                                onChange={event => {
                                    setFromTimePeriod(event.target.value);
                                }}
                            />
                        </td>
                        <td>To*</td>
                        <td>
                            <input  
                                type='date'
                                required
                                value={toTimePeriod}
                                onChange={event => {
                                    setToTimePeriod(event.target.value);
                                }}
                            />
                        </td>
                      
                    </tr>
                    
                </tbody>
            </table>
            {isLoading ? <Loader /> : <button className='block'>Save</button>}
            {!success && <div className='error'>{err} </div>} 
            {success && <div className='success'>Payroll Added Successfully!</div>}
            </form>
           <button onClick={() => console.log(fromYear)}>See Date</button>
        </div>
     );
     
}
 
export default AddMonthlyPayroll;