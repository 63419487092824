import {useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {useQuery} from 'react-query';
// import { GetDataWorkers} from './Data';
import CardWorker from '../../components/CardWorker';
import '../Styles/Worker.css';
import '../Styles/AddWorker.css';
import api from '../../contexts/BaseUrl';

function Worker () {

    let params = useParams();
    var workerId = params.userId;

    const getWorkerInfo = async () => {
        const {data} = await api.get(`/api/workers/get-worker/${workerId}`)
        return data;
    }
    const {data} = useQuery("userInfo", getWorkerInfo);
    let worker = data;

    const navigate = useNavigate();


    
    return ( 
        <div>
            <div className='heading'>Employee Details</div>
            <div className='workerTable'>
            <table id='workerTable'>
                <tbody>
                    <tr>
                        <td id='title'>Name:</td>
                        <td>{worker.name}</td>
                        <td id='title'>Employee ID</td>
                        <td>{worker.userId} </td>
                    </tr>
                    <tr>
                        <td id='title'>Phone Number</td>
                        <td>
                            {worker.phoneNumber}
                        </td>
                        <td id='title'>Joining Date</td>
                        <td>
                           {worker.dateOfJoining}
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Service Length</td>
                        <td>
                          {worker.lengthOfService}
                        </td>
                        <td id='title'>Permanent Date</td>
                        <td>
                           {worker.dateOfPermanent}
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Designation</td>
                        <td>
                            {worker.designation}
                        </td>
                        <td id='title'>Sub Designation</td>
                        <td>
                            {worker.subDesignation}
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Department</td>
                        <td>
                            {worker.department}
                        </td>     
                    </tr>
                    <tr>
                        <td id='title'>Section</td>
                        <td>
                           {worker.section}
                        </td>
                        <td id='title'>Sub Section</td>
                        <td>
                           {worker.subSection}
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Line</td>
                        <td>
                          {worker.line}
                        </td>
                        <td id='title'>Shift</td>
                        <td>
                            {worker.shift}
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Gender</td>
                        <td>
                            {worker.gender}
                        </td>
                        <td id='title'>Employment Status</td>
                        <td>
                           {worker.employmentStatus}
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>NID/Birth Rego</td>
                        <td>
                            {worker.nid}
                        </td>
                        <td id='title'>Age</td>
                        <td>
                           {worker.age}
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Father/Husband Name</td>
                        <td>
                            {worker.fatherHusbandName}
                        </td>
                        <td id='title'>Mother's Name</td>
                        <td>
                            {worker.motherName}
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Religion</td>
                        <td>
                            {worker.religion}
                        </td>
                        <td id='title'>Marital Status</td>
                        <td>
                            {worker.maritalStatus}
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Blood Group</td>
                        <td>
                           {worker.bloodGroup}
                        </td>
                        <td id='title'>Birth Date</td>
                        <td>
                            {worker.birthDate}
                        </td>
                    </tr>
                    <tr>
                    <td id='title'>Present Address</td>
                        <td>
                          {worker.presentAddress}
                        </td>
                        <td id='title'>Permanent Address</td>
                        <td>
                            {worker.permanentAddress}
                        </td>
                    
                    </tr>
                </tbody>
            </table>
            <button className='block' onClick={() => navigate('edit')}>Edit Details</button>
            </div>
           
           
        </div>
        
     );
}


export default Worker;
