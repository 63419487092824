import {useState} from 'react';
import {useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import api from '../../contexts/BaseUrl';
import Loader from '../../components/Loader';
import '../Styles/AddBuyer.css';
import '../Styles/AddPurchaseOrder.css';

const AddPurchaseOrder = () => {
    
    const params = useParams();
    const buyerId = params.buyerId;
    const buyerName = params.buyerName;
    const title = params.buyer;
    const [titleValue, setTitleValue] = useState('');
    const purchaserOrderId = params.articleId;
    

    const [success, setSuccess] = useState(null);
    const [err, setErrMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    var urlMain = `api/articles/create-article?ArticleNumber=${titleValue}&purchaseOrderId=${purchaserOrderId}`
    
    const handleSubmit =  async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try{
            const response = await api.post(urlMain,
            {
                headers: {
                    "Content-Type" : "application/json"
                }
            });

       setTitleValue('')
       setIsLoading(false);
       setSuccess(true);  
       navigate(-1);   
            
        }catch(err){

            // setUserId('');
            // setName('');
            // setPhoneNumber('');
            // setDateOfJoining('');
            // setDepartment('');
            setSuccess(false);
            setIsLoading(false);
            setTitleValue('');
            if(!err?.response){
                setErrMsg('No Server Response')
            }else if(err.response.status === 400){
                setErrMsg('Already exist !');
            }else{
                setErrMsg("Failed")
            }
            
        }
    }

    return ( 
        
        <div>
            <div style={{ marginTop: 20 }}>.</div>
            <div className='heading'> Add {buyerName} Article</div>
            
            <form className='addBuyer' onSubmit={handleSubmit}>
            <div className='addPurchaseOrder'>
                <table>
                    <tbody>
                        <tr>
                            <td>Article Number</td>
                            <td>
                                <input 
                                    type='text'
                                    required
                                    value={titleValue}
                                    onChange={(event) => setTitleValue(event.target.value) }
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
           
            {isLoading ? <Loader /> : <button id='addButton'>Save</button>}
            {!success && <div className='error'>{err} </div>} 
            {success && <div className='success'>{title} Added Successfully!</div>}
            </form>
        </div>
     );
     
}
 
export default AddPurchaseOrder;