import { useParams, useNavigate } from 'react-router-dom';
import {useQuery} from 'react-query';
// import { GetDataWorkers} from './Data';
import '../Styles/Worker.css';
import '../Styles/AddWorker.css';
import api from '../../contexts/BaseUrl';

function PayrollDetails () {

    let params = useParams();
    var payrollId = params.id;

     const getPayrollInfo = async () => {
        const {data} = await api.get(`/api/payrollsmonthly/get-payroll/${payrollId}`)
        return data;
    }
    const {data} = useQuery("userInfo", getPayrollInfo);
    let worker = data;

    const navigate = useNavigate();

    // return(
    //     <div>
    //         <span>Payroll Details</span>
    //         <button onClick={() => console.log(worker.employeeName)}>See Payroll</button>
    //     </div>
    // );


    
    return ( 
        <div>
            <div className='heading'>Payroll Details</div>
            {/* <button onClick={() => console.log(worker)}>See Details</button> */}
            <div className='workerTable'>
            <table id='workerTable'>
                <tbody>
                    <tr>
                        <td id='title'>Name:</td>
                        <td>{worker.employeeName}</td>
                        <td id='title'>Employee ID</td>
                        <td>{worker.employeeId} </td>
                    </tr>
                    <tr>
                        <td id='title'>Joining Date</td>
                        <td>
                           {worker.joiningDate}
                        </td>
                        <td id='title'>Time Period</td>
                        <td>
                            {worker.timePeriod}
                        </td>   
                    </tr>
                    <tr>
                        <td id='title'>Gross Salary</td>
                        <td>
                          {worker.grossSalary}
                        </td>
                        <td id='title'>Basic Salary</td>
                        <td>
                           {worker.basicSalary}
                        </td>
                    </tr>
                    <tr>
                        <td id='title2'>Benefits</td>
                    </tr>
                    <tr>
                        <td id='title'>House Rent</td>
                        <td>
                          {worker.houseRent}
                        </td>
                        <td id='title'>Maternity Leave</td>
                        <td>
                           {worker.mlBenefits}
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Attendance Bonus</td>
                        <td>
                          {worker.attendanceBonus}
                        </td>
                        <td id='title'>Others</td>
                        <td>
                           {worker.others}
                        </td>
                    </tr>
                    <tr>
                        <td id='title2'>Absent/Leave</td>
                    </tr>
                    <tr>
                        <td id='title'>Absent Days</td>
                        <td>
                          {worker.totalAbsentDays}
                        </td>
                        <td id='title'>Attend Days</td>
                        <td>
                           {worker.totalAttendDays}
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Leave Days</td>
                        <td>
                          {worker.totalLeaveDays}
                        </td>
                        <td id='title'>No Pay Leave Days</td>
                        <td>
                           {worker.noPayLeaveDays}
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Total Holidays</td>
                        <td>
                            {worker.totalHolidays}
                        </td>
                        <td id='title'>Late Days</td>
                        <td>
                            {worker.lateDays}
                        </td>
                      
                    </tr>
                    <tr>
                        <td id='title'>Total Pay Days</td>
                        <td>
                            {worker.totalPayDays}
                        </td>
                    </tr>    
                    <tr>
                        <td id='title2'>Overtime/Leave</td>
                    </tr>
                    <tr>
                        <td id='title'>Overtime (Hours)</td>
                        <td>
                          {worker.totalOverTimeHours}
                        </td>
                        <td id='title'>Early Leave (Minutes)</td>
                        <td>
                           {worker.totalEarlyLeave}
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Overtime Rate</td>
                        <td>
                          {worker.overTimeRate}
                        </td>
                        <td id='title'>Overtime Amount</td>
                        <td>
                           {worker.overTimeAmount}
                        </td>
                    </tr>
                    <tr>
                        <td id='title2'>Deduction</td>
                    </tr>
                    <tr>
                        <td id='title'>Advanced Deduction</td>
                        <td>
                          {worker.advanceDeduction}
                        </td>
                        <td id='title'>Tax Deduction</td>
                        <td>
                           {worker.taxDeduction}
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Others Deduction</td>
                        <td>
                          {worker.othersDeduction}
                        </td>
                        <td id='title'>Total Deduction</td>
                        <td>
                           {worker.totalDeduction}
                        </td>
                    </tr>
                    <tr>
                        <td id='title2'>Earning / Salary</td>
                    </tr>
                    <tr>
                        <td id='title'>Earning</td>
                        <td>
                          {worker.earning}
                        </td>
                        <td id='title'>Arrear</td>
                        <td>
                           {worker.arrear}
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Total Salary</td>
                        <td>
                          {worker.totalSalary}
                        </td>
                        <td id='title'>Total Earnings</td>
                        <td>
                           {worker.totalEarnings}
                        </td>
                       
                    </tr>
                    <tr>
                        <td id='title'>Remarks</td>
                    </tr>
                    <tr>
                        <td id='title2'>Net Pay</td>
                        <td id='title2'>Tk {worker.netPay}</td>
                    </tr>
                    
                </tbody>
            </table>
            <button className='block' onClick={() => navigate('edit')}>Edit Details</button>
            </div>
           
           
        </div>
        
     );
}


export default PayrollDetails;
