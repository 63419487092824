import {useParams, useNavigate} from 'react-router-dom';
import {useState} from 'react';
import api from '../../contexts/BaseUrl';
import {useQuery} from 'react-query';
import Loader from '../../components/Loader';

function DeleteArticle(){

    const params = useParams();
    const navigate = useNavigate();

    const getArticles = async () => {
        const {data} = await api.get(`api/purchaseorders/get-individual-po/${params.purchaseOrderId}`);
        return data;
    }

    const {data} = useQuery("buyerArticle", getArticles);
    let buyer = data;

    const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(-1);
    const [success, setSuccess] = useState(null);
    const [err, setErrMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit =  async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try{
            const response = await api.post(`api/articles/delete-article?id=${selectedPurchaseOrder}`,
                {
                    headers: {
                        "Content-Type" : "application/json"
                    }
                });

            setSuccess(true);
            setIsLoading(false);
            navigate(-1);    
        }catch(err){

            setSuccess(false);
            setIsLoading(false);
            if(!err?.response){
                setErrMsg('No Server Response')
            }else{
                setErrMsg('Failed Updating!');
            }
        }
    }

    return(
        <form className='login' onSubmit={handleSubmit}>
        <label style={{ fontWeight: '900', fontSize: '18px', padding: '10px' }}>Choose Article</label>
        <label>
             <select style={{marginLeft: '10px'}} required onChange={(event) => setSelectedPurchaseOrder(event.target.value)}>
             <option key={1} value=''>Choose Artice </option>
             {buyer.articles.map( (item,key) => 
                 <option key={key} value={item.id}>{item.articleNumber}</option>
                 )}
             </select>
         </label>
         {isLoading ? <Loader /> : <button>Delete</button>}
         {!success && <div className='error'>{err} </div>}
     </form>
    )
}

export default DeleteArticle;