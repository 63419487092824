import './Styles/CustomButton.css'


function CustomButton(props){


    return ( 
            <div className='parent'>
                <button 
                    onClick={props.onClick}
                    className='buttonwrapper'
                >{props.title}</button>

            </div>      
            
     );
}
 
export default CustomButton;