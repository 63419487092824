import {useState, useEffect} from 'react';
import {useQuery} from 'react-query';
import {useParams, useNavigate} from 'react-router-dom';
import api from '../../contexts/BaseUrl';
import CustomButton from '../../components/CustomButton';
import '../Styles/PurchaseOrderArticle.css';
import axios from 'axios';

function PurchaseOrderArticle(){

    const navigate = useNavigate();
    const params = useParams();

    const [articles, setArticles] = useState([]);
    const [articleId, setArticleId] = useState(0);

    

    const getPurchaseArticles = async () => {
        const {data} = await api.get(`api/purchaseorders/get-individual-po/${params.purchaseOrderId}`);
        return data;
    }

    const {data} = useQuery("buyerPurchaseArticle", getPurchaseArticles);
    let buyer = data;

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [err, setErrExportMsg] = useState('');

    var urlExport = `https://bling.porichorja.org/api/articleitems/download-articleitem?purchaseorderid=${params.purchaseOrderId}`
    const handleExportSubmit = () => { 
        setIsLoading(true);  
        try{
            axios.get(urlExport,{
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]), {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'ArticleItem.xlsx');
                document.body.appendChild(link);
                link.click();
                setIsLoading(false);
            });
            setIsLoading(false);
           
        }catch(err){
            setSuccess(false);
            setIsLoading(false);
            if(!err?.response){
                setErrExportMsg('No Server Response')
            }else{
                setErrExportMsg('Failed Exporting!');
            } 
        }
    }

    useEffect(() => {
        if(articleId !== 0){
            api.get(`api/articles/get-individual-article/${articleId}`)
            .then(response => {
                setArticles(response.data);        
            }).catch(err => {
                console.log("Fetching article failed")
                }
            )
        }
       }, [articleId])
    

    return(
        <div>
            <div className='leftBar'>
                <h2 style={{ textAlign: 'center' }}>Article Number</h2>
                <table className='buyerName'>
                    {buyer.articles.map((a, key) => {
                        return(
                            <tbody key={key}>
                                <tr onClick={() => setArticleId(a.id)}>
                                    <td>{a.articleNumber}</td>
                                </tr>
                            </tbody>
                        )
                    })}
                </table>
            </div>
            <div className='rightBar'>
                    <CustomButton 
                        title='Add Article'
                        onClick={() => navigate(`add-article/${buyer.id}`)}
                    />
                    <CustomButton 
                        title='Delete Article'
                        onClick={() => navigate('delete-article/delete')}
                    />
                    <CustomButton
                        title='Add Item'
                        onClick={() => navigate('add-item')}
                    />
                    <CustomButton
                        title='Download Purchase Order'
                        onClick={handleExportSubmit}
                    />
            </div>

            <div className='allUsers'>
                {articles.articleItems == null &&<h1>{params.buyerName} - {buyer.poNumber} </h1>}
                {articles.articleItems != null &&
                    <div className='orderItemAll'> 
                        <h1>{params.buyerName} - {buyer.poNumber} - {articles.articleNumber}</h1>
                        <table>
                            <thead>
                                <tr>
                                    <th>Item Name</th>       
                                    <th>Item Color</th>
                                    <th>Item Unit</th>
                                    <th>Remaining Quantity</th>
                                    <th>Details</th>
                                    {/* <th>Edit</th> */}
                                   
                                </tr>         
                            </thead>
                            {articles.articleItems.map((a, key) => {
                                return(
                                    <tbody key={key}>
                                        <tr>
                                            <td>{a.itemName}</td>
                                            <td>{a.itemColor}</td>
                                            <td>{a.itemUnit}</td>
                                            <td>{a.remainingQuantity}</td>
                                            <td>
                                                <button onClick={() => navigate(`articleItemId/${a.id}`)}>View</button>
                                            </td>
                                            {/* <td onClick={() => localStorage.setItem('edit', 1)}>
                                                <button onClick={() => navigate(`articleItemId/${a.id}/edit`)}>Edit</button>
                                            </td> */}
                                           
                                        </tr>
                                    </tbody>
                                )
                            })}
                        </table>
                       
                    </div>
                }
            </div>
        </div>
    )
}

export default PurchaseOrderArticle;