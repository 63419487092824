import api from '../contexts/BaseUrl';
import {useState} from 'react';
import './Styles/ForgotPassword.css';

const ForgotPassword = () => {

    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);
    const [err, setErrMsg] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            const response = await api.post(
                '/api/accounts/forgot-password',
                JSON.stringify({email}),
                {
                    headers: {
                        'Access-Control-Allow-Origin' : '*',
                        "Content-Type" : "application/json"
                    }
                }
            )
            setSuccess(true);
            setErrMsg("Password reset link has been sent to your email");

        }catch(err){
      
            setSuccess(false);
            setErrMsg("Something went wrong!");
            
        }
    }

    return ( 
        <form className='login' onSubmit={handleSubmit}>
            <label>Email</label>
            <input 
                type="email"
                required
                placeholder= 'What is your email?'
                value={email}
                onChange={event => {setEmail(event.target.value)}}
            />
            <button className='block'>Submit</button>

            {!success && <div className='error'>{err}</div>}
            {success && <div className='error'>Password reset link has been sent to your email!</div>}
        </form>
     );
}
 
export default ForgotPassword;