import {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom"
import api from '../../contexts/BaseUrl';
import '../Styles/Home.css';
import Loading from '../../components/Loading';
import axios from 'axios';


import CustomButton from '../../components/CustomButton';


function Payroll() {

    const navigate = useNavigate();

    const [allData, setAllData] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [searchDate, setSearchDate] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [success, setSuccess] = useState(false);
    const [err, setErrExportMsg] = useState('');
    // const getWorkerInfo = async () => {
    //     const {data} = await api.get('/api/workers')
    //     setFilteredData(data);
    //     return data;
    // }

    // const {data} = useQuery("userInfo", getWorkerInfo);

    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState([]);

    const changeParam = () => {
        if(pagination.totalPages > pagination.currentPage){
            setPage(page+1);
       }
        // navigate({
        //     pathname:'/hr-staff',
        //     search: createSearchParams({
        //         pageNumber: page
        //     }).toString()
        // });

    };

    const changeParamBack = () => {
        if(page>1){
            setPage(page-1);
        }
    };

    const handleChange = (event) => {      
        if(event.target.value > pagination.totalPages){
            var num = pagination.totalPages;
            setPage(num);
        }else{
            setPage(event.target.value)
        }
    }

  
  var params;

  if(searchInput.length > 0){
    params = new URLSearchParams({
        checkUser: searchInput
      }).toString();
  }else if(searchDate.length > 0){
    params = new URLSearchParams({
        checkDate: searchDate
      }).toString();
  }else if(searchInput.length > 0 && searchDate.length > 0){
    params = new URLSearchParams({
        checkDate: searchDate,
        checkUser: searchInput
      }).toString();
  }else{
    params = new URLSearchParams({
        pageNumber: page,
      }).toString();
  }
   
  const url = "api/payrollsmonthly/get-monthly-payroll?" + params;

    useEffect(() => {
        setIsLoading(true);
        api.get(url)
        .then(response => {
            setAllData(response.data);
            setIsLoading(false);
            setPagination(JSON.parse(response.headers.pagination));
        }).catch(err => {
            console.log("Getting data failed");
            setIsLoading(false);
            },
        )}, [url]);

        var urlExport;
        if(searchDate.length>4){
            urlExport = `https://bling.porichorja.org/api/payrollsmonthly/download-payroll?date=${searchDate}`
        }else{
            urlExport = `https://bling.porichorja.org/api/payrollsmonthly/download-payroll`
        }
        const handleExportSubmit = () => { 
            setIsLoading(true);  
            try{
                axios.get(urlExport,{
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]), {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Attendance.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    setIsLoading(false);
                });
                setIsLoading(false);
               
            }catch(err){
                setSuccess(false);
                setIsLoading(false);
                if(!err?.response){
                    setErrExportMsg('No Server Response')
                }else{
                    setErrExportMsg('Failed Exporting!');
                } 
            }
        }
    return ( 
        
        <div>  
            <div className='leftBar'>
                <CustomButton
                    title='Home'
                    onClick={() => navigate('/hr-staff')}
                />
                <button onClick={() => console.log(searchDate)}>See Date</button>
            </div>
            <div className='rightBar'>
    
                <CustomButton
                        title='Create Individual Payroll'
                        onClick={() => navigate('individual')}
                    />
                 <CustomButton
                        title='Edit Individual Payroll'
                        onClick={() => navigate('edit')}
                    />
                 <CustomButton
                        title='Create Monthly Payroll'
                        onClick={() => navigate('monthly')}
                    /> 
                 <CustomButton
                        title='Download Payroll'
                        onClick={handleExportSubmit}
                    />   
            </div>
            
            <div className="workers">
                <div className="workersContent">
                    <div className='heading'>
                        <input
                            type='date' 
                            className='inputDate'
                            value={searchDate}
                            onChange={(event) => setSearchDate(event.target.value)}
                        />
                    </div>
                        <input 
                            type="text"
                            className='searchBar'
                            value = {searchInput}
                            placeholder="Search by Employee ID or Employee Name"
                            onChange={(event) => setSearchInput(event.target.value.toLowerCase()) } 
                        />
                     {isLoading ? <Loading /> :
                    <table>
                        <thead>
                        <tr>
                            <th>Employee Id</th>
                            <th>Name</th>
                            <th>Joining Date</th>
                            <th>Time Period</th>
                            <th>Gross Salary</th>
                            <th>Details</th>
                        </tr>
                        </thead>
                        
                   
                    {allData.map ((a, key) => {
                        return (
                        <tbody key={key}>
                        <tr>
                                <td>{a.employeeId}</td>
                                <td>{a.employeeName}</td>
                                <td>{a.joiningDate}</td>
                                <td>{a.timePeriod}</td>
                                <td>{a.grossSalary}</td>
                                <td><button onClick={() => navigate(`${a.id}`)}>View Payroll</button></td>
                        </tr>
                        </tbody>
                        )
                    })}
                

                    </table>}
                    <div className='changePageFooter'>
                        <button id='backButton' onClick={changeParamBack}>Back</button>
                        <input
                            id='inputFooter'
                            value={page}
                            onChange={handleChange}
                        />
                        <span id='totalPage'>/ {pagination.totalPages}</span>
                        <button id='nextButton' onClick={changeParam}>Next</button>
                    </div>
                </div>
                
            
            </div>
        </div>
        
     );
}
 
export default Payroll;

