import { useParams, useNavigate } from 'react-router-dom';
import { GetDataAllUsers} from '../HRStaff/Data';
import {Dropdown} from 'react-dropdown-now';
import {useState, useContext, useEffect} from 'react';
import AuthProvider from '../../contexts/AuthProvider';
import api from '../../contexts/BaseUrl';
import {useQuery} from 'react-query';
import Card from '../../components/Card'; 
import 'react-dropdown-now/style.css';



function User () {

    let params = useParams();
    var userId = params.id;
    const navigate = useNavigate();
    // const [role, setRole] = useState(a.roles);


    // const {auth} = useContext(AuthProvider);
    const user = localStorage.getItem('auth');
    const userObj = JSON.parse(user);

    const [err, setErrMsg] = useState('');
    const [success, setSuccess] = useState('');

    const [selectedRole, setSelectedRole] = useState('')
    const [userAccount, setUserAccount] = useState([]);

    
    useEffect(() => {
        api.get(`/api/accounts/get-user?id=${userId}`,{
            headers : {
                Authorization : `Bearer ${userObj.token}`
            }
        }).then(response => {
            setUserAccount(response.data);
        })
    },[])

    const getAllRoles = async () => {
        const {data} = await api.get('/api/roles/all-roles',{
            headers:{Authorization: `Bearer ${userObj.token}` }
        });
        return data;
    }

    const {data} = useQuery("allRoles", getAllRoles);   

    const getToken = `${userObj.token}`;

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('/api/roles/change-role',
            {
                email: userAccount.email,
                roleName: selectedRole
            }, 
            {
                headers: {
                    'Access-Control-Allow-Origin' : '*',
                    Authorization: `Bearer ${userObj.token}`,
                    'Content-Type': 'application/json',
                    
                }
            }
            )
            setSuccess('true');
            navigate(-1);
        }catch(err){
            if(!err?.response){
                setErrMsg('No Server Response')
            }else{
                setErrMsg('Failed changing password!');
            }
            setSuccess('false');
        }
    }
    
    return ( 
        <div>
            
            <div className='login'>
                <label style={{ fontWeight: '900', fontSize: '18px', padding: '10px' }}>Name</label>
                <label style={{ paddingLeft: '10px' }}>{userAccount.displayName}</label>
                <label style={{ fontWeight: '900', fontSize: '18px', padding: '10px' }}>Email</label>
                <label style={{ paddingLeft: '10px' }}>{userAccount.email}</label>
                <label style={{ fontWeight: '900', fontSize: '18px', padding: '10px' }}>Role</label>
                <label>
                    <select style={{marginLeft: '10px'}} required onChange={(event) => setSelectedRole(event.target.value)}>
                    <option key={1} value=''>Choose Role</option>
                    {data.map( (item,key) => 
                        <option key={key} value={item.name}>{item.name}</option>
                        )}
                    </select>
                </label>
                
                <button style={{ width: '200px', fontWeight:'500', fontSize: '18px' }} onClick={handleSubmit}>Save</button>
            </div>
      
        </div>
        
     );
}
 
export default User;
