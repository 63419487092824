import {useState} from 'react';
import {useQuery} from 'react-query';
import {useParams, useNavigate} from 'react-router-dom'; 
import api from '../../contexts/BaseUrl';
import Loader from '../../components/Loader';

const AttendanceEdit = () => {

    let params = useParams();
    const navigate = useNavigate();

    let attendance = GetDataAttendances(params.id);

    const [attendances, setAttendances] = useState(attendance);
    const [date, setDate] = useState(attendances.date)
    const [timeIn, setTimeIn] = useState(attendances.timeIn);
    const [timeOut, setTimeOut] = useState(attendances.timeOut);
    const [overTime, setOverTime] = useState(attendance.overTime);
    const [earlyLeave, setEarlyLeave] = useState(attendance.earlyLeave);

    const [success, setSuccess] = useState(null);
    const [err, setErrMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit =  async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try{
            const response = await api.post(`api/attendances/${params.id}`,
                {
                  date: date,
                  timeIn: timeIn,
                  timeOut: timeOut,
                  overTime: overTime,
                  earlyLeave: earlyLeave       
                },
                {
                    headers: {
                        "Content-Type" : "application/json"
                    }
                });
            setDate('');
            setTimeIn('');
            setTimeOut('');
            setOverTime('')
            setEarlyLeave('');
            setIsLoading(false);
            setSuccess(true);
            navigate(-1);
            
        }catch(err){
            setSuccess(false);
            setIsLoading(false);
            if(!err?.response){
                setErrMsg('No Server Response')
            }else{
                setErrMsg('Failed updating attendance!');
            }
        }
    }

    return(
        <div>
            
            <button onClick={() => console.log(params.id)}>See ID</button>
            <button onClick={() => console.log(attendances)}>See Attendance</button>
            <div className='heading'>Update Attendance For -</div>
            <div className='heading2'>Employee ID: {attendances.userId} </div>
            <form className='addWorkerTable' onSubmit={handleSubmit}>
            <table id='workerTable'> 
                <tbody>
                    <tr>
                        <td>Date*</td>
                        <td>
                            <input  
                                type='date'
                                required
                                value={date}
                                onChange={event => {
                                    setDate(event.target.value);
                                }}
                            />
                        </td>
                       
                    </tr>
                    <tr>
                        <td>Time In*</td>
                        <td>
                            <input  
                                type='time'
                                required
                                value={timeIn}
                                onChange={event => {
                                    setTimeIn(event.target.value);
                                }}
                            />
                        </td>
                        <td>Time Out*</td>
                        <td>
                            <input  
                                type='time'
                                required
                                value={timeOut}
                                onChange={event => {
                                    setTimeOut(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Over Time (Minutes)</td>
                        <td>
                            <input  
                                type='number'
                                value={overTime}
                                onChange={event => {
                                    setOverTime(event.target.value);
                                }}
                            />
                        </td>
                        <td>Early Leave (Minutes)</td>
                        <td>
                            <input  
                                type='number'
                                value={earlyLeave}
                                onChange={event => {
                                    setEarlyLeave(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    
                </tbody>
            </table>
            {isLoading? <Loader/> : <button className='block'>Save</button>}
            {!success && <div className='error'>{err} </div>} 
            {success && <div className='success'>Roster Updated Successfully!</div>}
            </form>
        </div>
    )
}

function GetDataAttendances(number){
    const getAttendanceInfo = async () => {
        const {data} = await api.get('/api/attendances/get-attendances')
        return data;
    }

    const {data} = useQuery("attendanceInfo", getAttendanceInfo);

    return data.find(
        (user) => user.id === number
    );
}
export default AttendanceEdit;