import {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import api from '../../contexts/BaseUrl';
import Loader from '../../components/Loader';
import {useQuery} from 'react-query';



function ReceivedDataUpdate(){

    const navigate = useNavigate();

    let params = useParams();
    var articleItemId = params.id;

    const [dateValue, setDateValue] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [err, setErrMsg] = useState('');
    

    const [articleItem, setArticleItem] = useState([]);

    useEffect(() => {
         api.get(`api/articleItems/get-individual-articleItem?articleItemId=${articleItemId}`,{
            headers: { 'Content-Type' : 'application.json'}
        }).then(response => {
            setArticleItem(response.data)
        })
    },[articleItemId])


    const updateArticleItem = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        var totalReceivedQuantity = 0;
        var totalOutQuantity = 0;
        for(var i = 0; i<articleItem.receivedDates.length; i++){
            totalReceivedQuantity += articleItem.receivedDates[i].quantity;
        }
        for(var i = 0; i<articleItem.outDates.length; i++){
            totalOutQuantity += articleItem.outDates[i].quantity;
        }
        var remainingQuantity = totalReceivedQuantity - totalOutQuantity;

        try{
            const response = await 
                api.post(`api/articleItems/edit?Id=${articleItemId}&ItemName=${articleItem.itemName}&ItemUnit=${articleItem.itemUnit}&ItemColor=${articleItem.itemColor}
                &TotalReceivedQuantity=${totalReceivedQuantity}
                &TotalOutQuantity=${totalOutQuantity}&RemainingQuantity=${remainingQuantity}&Remarks=${articleItem.remarks}`,
               
                {
                    headers: {
                        "Content-Type" : "application/json"
                    }
                });

            navigate(-2);
            setSuccess(true);           
            setIsLoading(false);
            
            
        }catch(err){

            setSuccess(false);
            setIsLoading(false);
            if(!err?.response){
                setErrMsg('No Server Response')
            }else{
                setErrMsg('Failed Updating!');
            }
        }
    }

  

    return(
        <div>        
            <div className='addBuyer'>
             {isLoading ? <Loader /> : <button id='updateButton' onClick={updateArticleItem}>Update Article</button>}
            </div> 
        </div>
    )
}

export default ReceivedDataUpdate;