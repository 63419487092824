import {useParams, useNavigate} from 'react-router-dom';
import {useQuery} from 'react-query';
import {useState, useEffect} from 'react';
import api from '../../contexts/BaseUrl';
import Loader from '../../components/Loader';

function ReceivedDataEdit(){

    if(localStorage.getItem('editOut') != null){
        window.location.reload();
        localStorage.removeItem('editOut');
    }

    const navigate = useNavigate();

    const params = useParams();
    const receivedDataId = params.outId;


    const getOutInfo = async () => {
        const {data} = await api.get(`/api/outdates/get-individual-out?id=${params.outId}`)
        return data;
    }
    const {data} = useQuery("userInfo", getOutInfo);
    let outData = data;

   const [quantity, setQuantity] = useState(outData.quantity);

    const [success, setSuccess] = useState(null);
    const [err, setErrMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

   const handleSubmit =  async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try{
        const response = await api.post(`api/outdates/edit?Id=${params.outId}&Date=${outData.date}&Quantity=${quantity}&Section=${outData.section}`,
            {
                headers: {
                    "Content-Type" : "application/json"
                }
            });

        setSuccess(true);
        setIsLoading(false);
        navigate(-1);
        
    }catch(err){

        setSuccess(false);
        setIsLoading(false);
        if(!err?.response){
            setErrMsg('No Server Response')
        }else{
            setErrMsg('Failed Updating!');
        }
    }
}

    return(
        <div>
            <div className='heading'>Update Out Data</div>
            <form className='login' onSubmit={handleSubmit}>
                <label style={{ fontWeight: '900', fontSize: '18px', padding: '10px' }}>Id</label>
                <label style={{ paddingLeft: '10px' }}>{outData.id}</label>
                <label style={{ fontWeight: '900', fontSize: '18px', padding: '10px' }}>Date</label>
                <label style={{ paddingLeft: '10px' }}>{outData.date}</label>
                <label style={{ fontWeight: '900', fontSize: '18px', padding: '10px' }}>Section</label>
                <label style={{ paddingLeft: '10px' }}>{outData.section}</label>
                <label style={{ fontWeight: '900', fontSize: '18px', padding: '10px' }}>Quantity</label>
                <label>
                    <input 
                        type='number'
                        value= {quantity}
                        onChange={(event) => setQuantity(event.target.value)}
                    />
                </label>

                {isLoading ? <Loader /> : <button style={{ width: '200px', fontWeight:'500', fontSize: '18px' }}>Save</button>}
                {!success && <div className='error'>{err} </div>}
            </form>

        </div>
    )
}

export default ReceivedDataEdit;