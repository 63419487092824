import {useState} from 'react';
import {useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import api from '../../contexts/BaseUrl';
import Loader from '../../components/Loader';
import '../Styles/AddWorker.css';

const AddAttendance = () => {

    
    const [userId, setUserId] = useState('');
    const [date, setDate] = useState('');
    const [timeIn, setTimeIn] = useState('');
    const [timeOut, setTimeOut] = useState('');

    const [success, setSuccess] = useState(null);
    const [err, setErrMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const handleSubmit =  async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try{
            const response = await api.post('api/attendances/create-attendance' ,
                {
                  userId: userId,  
                  date: date,
                  timeIn: timeIn,
                  timeOut: timeOut,  
                },
                {
                    headers: {
                        "Content-Type" : "application/json"
                    }
                });

            setUserId('');
            setDate('');
            setTimeIn('');
            setTimeOut('');
            setSuccess(true);
            setIsLoading(false);
            
            
        }catch(err){

            // setUserId('');
            // setName('');
            // setPhoneNumber('');
            // setDateOfJoining('');
            // setDepartment('');
            setSuccess(false);
            setIsLoading(false);
            if(!err?.response){
                setErrMsg('No Server Response')
            }else{
                setErrMsg('Failed adding worker!');
            }
        }
    }

    return ( 
        
        <div>
            <div className='heading'> Add New Attendance</div>
            
            <form className='addWorkerTable' onSubmit={handleSubmit}>
            <table id='workerTable'>
                
                <tbody>
                    <tr>
                        <td>Employee ID*</td>
                        <td>
                            <input  
                                type='string'
                                required
                                value={userId}
                                onChange={event => {
                                    setUserId(event.target.value);
                                }}
                            />
                        </td>
                        <td>Date*</td>
                        <td>
                            <input  
                                type='date'
                                required
                                value={date}
                                onChange={event => {
                                    setDate(event.target.value);
                                }}
                            />
                        </td>
                       
                    </tr>
                    <tr>
                        <td>Time In*</td>
                        <td>
                            <input  
                                type='time'
                                required
                                value={timeIn}
                                onChange={event => {
                                    setTimeIn(event.target.value);
                                }}
                            />
                        </td>
                        <td>Time Out*</td>
                        <td>
                            <input  
                                type='time'
                                required
                                value={timeOut}
                                onChange={event => {
                                    setTimeOut(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    
                </tbody>
            </table>
            {isLoading ? <Loader /> : <button className='block'>Save</button>}
            {!success && <div className='error'>{err} </div>} 
            {success && <div className='success'>Attendance Added Successfully!</div>}
            </form>
        </div>
     );
     
}
 
export default AddAttendance;