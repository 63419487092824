import {useState} from 'react';
import {useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import api from '../../contexts/BaseUrl';
import Loader from '../../components/Loader';
import '../Styles/AddWorker.css';

const AddRoster = () => {

    let params = useParams();

    // let worker = GetDataWorkers(params.userId);

    var workerId = params.userId;

    const getWorkerInfo = async () => {
        const {data} = await api.get(`/api/workers/get-worker/${workerId}`)
        return data;
    }
    const {data} = useQuery("userInfo", getWorkerInfo);
    let worker = data;
   
   
    
    const [basicSalary, setBasicSalary] = useState('');
    const [grossSalary, setGrossSalary] = useState('');

    const [success, setSuccess] = useState(null);
    const [err, setErrMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const handlePayroll = async () => {
        await api.get(`api/workers/update-payroll?userId=${workerId}`)
    }

    const handleSubmit =  async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try{
            
            const response = await api.post('api/payrolls/create-individual-payroll' ,
                {
                    employeeId: worker.userId,
                    employeeName: worker.name,
                    joiningDate: worker.dateOfJoining,
                    basicSalary: basicSalary,      
                    grossSalary: grossSalary
                },
                {
                    headers: {
                        "Content-Type" : "application/json"
                    }
                });   
            setBasicSalary('');
            setGrossSalary('');
            setSuccess(true);
            setIsLoading(false);
            handlePayroll();
            navigate(-2);
            
            
        }catch(err){

            // setUserId('');
            // setName('');
            // setPhoneNumber('');
            // setDateOfJoining('');
            // setDepartment('');
            setSuccess(false);
            setIsLoading(false);
            if(!err?.response){
                setErrMsg('No Server Response')
            }else{
                setErrMsg('Failed adding worker!');
            }
        }
    }

    return ( 
        
        <div>
            <div className='heading'> Add New Payroll</div>
            <div className="heading2">{worker.name} - {worker.userId} - {worker.department}</div>
            <form className='addWorkerTable' onSubmit={handleSubmit}>
            <table id='workerTable'>
                
                <tbody>
                   
                    <tr>
                        <td>Base Salary*</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={basicSalary}
                                onChange={event => {
                                    setBasicSalary(event.target.value);
                                }}
                            />
                        </td>
                        <td>Gross Salary*</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={grossSalary}
                                onChange={event => {
                                    setGrossSalary(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    
                </tbody>
            </table>
            {isLoading ? <Loader /> : <button className='block'>Save</button>}
            {!success && <div className='error'>{err} </div>} 
            {success && <div className='success'>Payroll Added Successfully!</div>}
            </form>
        </div>
     );
     
}
 
export default AddRoster;