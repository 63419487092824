import {useParams, useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {useQuery} from 'react-query';
import api from '../../contexts/BaseUrl';
import Loader from '../../components/Loader';

function DeletePurchaseOrder(){

    let params = useParams();
    var buyerName = params.buyerName;
    const navigate = useNavigate();

    const getPurchaseOrder = async () => {
        const {data} = await api.get(`api/buyers/get-individual-buyer/${buyerName}`);
        return data;
    }

    const {data} = useQuery("PurchaseOrder", getPurchaseOrder);
    let buyer = data;
    var purchaseOrder = buyer.purchaseOrders;

    const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(-1);

    const [success, setSuccess] = useState(null);
    const [err, setErrMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit =  async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try{
            const response = await api.post(`api/purchaseorders/delete-purchaseorder?id=${selectedPurchaseOrder}`,
                {
                    headers: {
                        "Content-Type" : "application/json"
                    }
                });

            setSuccess(true);
            setIsLoading(false);
            navigate(-1);    
        }catch(err){

            setSuccess(false);
            setIsLoading(false);
            if(!err?.response){
                setErrMsg('No Server Response')
            }else{
                setErrMsg('Failed Updating!');
            }
        }
    }

    return(
        <form className='login' onSubmit={handleSubmit}>
           
           <label style={{ fontWeight: '900', fontSize: '18px', padding: '10px' }}>Choose Purchase Order</label>
           <label>
                <select style={{marginLeft: '10px'}} required onChange={(event) => setSelectedPurchaseOrder(event.target.value)}>
                <option key={1} value=''>Choose Purchase Order</option>
                {purchaseOrder.map( (item,key) => 
                    <option key={key} value={item.id}>{item.poNumber}</option>
                    )}
                </select>
            </label>
            {isLoading ? <Loader /> : <button>Delete</button>}
            {!success && <div className='error'>{err} </div>}
        </form>
    );
}

export default DeletePurchaseOrder;