import {useNavigate} from 'react-router-dom';
import '../Styles/AddProperty.css';

function AddProperty(){

    const navigate = useNavigate();

    const properties = [
        {propertyId :"Buyer" , propertyName: "Add Buyers"},
        {propertyId :"Color" , propertyName: "Add Colors"},
        {propertyId :"Unit" , propertyName: "Add Units"},
        {propertyId :"Section" , propertyName: "Add Sections"},
    ]
    return(
        <div>
            <div className='leftBar'>
                .
            </div>
            <div className='rightBar'>
               .
            </div>
            <div className='allUsers'>
                <div className='propertyList'>
                    <ul>
                    {properties.map((reptile, key) => (   
                        <li key={key} onClick={() => navigate(`${reptile.propertyId}`)}>{reptile.propertyName}</li>
                        ))}
                    </ul>
                </div>
           
            </div>
        </div>
    )
}

export default AddProperty;