import '../Styles/AdminHome.css';
import {useState} from 'react';
import {useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import CustomButton from '../../components/CustomButton';
import '../Styles/StoreHome.css'
import api from '../../contexts/BaseUrl';



function StoreHome(){

    if(localStorage.getItem('auth') == null){
        window.location.reload();
    }
    const params = useParams();
    const navigate = useNavigate();

    const [purchaseOrder, setPurchaseOrder] = useState([]);
    const [filteredPurchaseOrder, setFilteredPurchaseOrder] = useState([]);
    const [buyerName, setBuyerName] = useState('');
    const [searchInput, setSearchInput] = useState('');

    const getBuyersInfo = async () => {
        const {data} = await api.get('api/buyers/get-buyers');
        return data;
    }

    const {data} = useQuery("buyerInfo", getBuyersInfo);
    let buyer = data;
    let result = [];
    
    // const handleSearch = (event) => {
    //     setSearchInput(event.target.value.toLowerCase());
    //     result = purchaseOrder.filter((m) => {
    //         return m.poNumber.toLowerCase().search(searchInput) !== -1 ;
    //         // return Object.values(data).join('').includes(searchInput);
    //     });
    //     setFilteredPurchaseOrder(result);
    //     //setPurchaseOrder(result)
    // }

    const buyerInfo = buyerName;

    function ShowPurchaseOrder(){
        return (
            <div>
                  <ul>
                 {purchaseOrder.map((reptile, key) => (   
                       <li key={key} onClick={() => navigate(`${buyerInfo}/${reptile.id}`)}>{reptile.poNumber}</li>
                       ))}
                 </ul>
            </div>
        )
    }

    function FilteredPurchaseOrder(){
        return (
            <div>
                  <ul>
                 {filteredPurchaseOrder.map((reptile, key) => (   
                       <li key={key}>{reptile.poNumber}</li>
                       ))}
                 </ul>
            </div>
        )
    }
   

    return (
         <div>
            <div className='leftBar'>
                .
                
            </div>
            <div className='rightBar'>
                <div className=''>
                    <CustomButton
                        title='Add Property'
                        onClick={() => navigate('add-property')}
                    />           
                </div>
            </div>
            <div className='allUsers'>
                <div className='storeHome'>
                  <ul>
                 {buyer.map((reptile, key) => (   
                       <li key={key} onClick={() => navigate(`${reptile.buyerName}`)}>{reptile.buyerName}</li>
                       ))}
                 </ul>
                </div>
            </div>
 
        </div>
    )
}

export default StoreHome;