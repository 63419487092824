import {useState, useEffect} from 'react';
import {useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import api from '../../contexts/BaseUrl';
import Loader from '../../components/Loader';
import '../Styles/AddWorker.css';

const UpdateRoster = () => {

    let params = useParams();

    // let roster = GetDataAllRosters(params.rosterId);
    // let worker = GetDataWorkers(params.userId)

    var workerId = params.userId;
    const getWorkerInfo = async () => {
        const {data} = await api.get(`/api/workers/get-worker/${workerId}`)
        return data;
    }
    const {data} = useQuery("userInfo", getWorkerInfo);
    let worker = data;

    var rosterId = parseInt(params.rosterId);

    var roster = worker.rosters;

    var checkRosterId = roster.filter(user => user.id === rosterId);

    var rosterData = checkRosterId[0];

   
   
    const navigate = useNavigate();
  
    const [date, setDate] = useState(rosterData.date);
    const [shiftFrom, setShiftFrom] = useState(rosterData.shiftFrom);
    const [shiftTo, setShiftTo] = useState(rosterData.shiftTo);

    const [success, setSuccess] = useState(null);
    const [err, setErrMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    const handleSubmit =  async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try{
            const response = await api.post(`api/rosters/${params.rosterId}`,
                {
                  date: date,
                  shiftFrom: shiftFrom,
                  shiftTo: shiftTo,       
                },
                {
                    headers: {
                        "Content-Type" : "application/json"
                    }
                });

            setDate('');
            setShiftFrom('');
            setShiftTo('');
            setIsLoading(false);
            setSuccess(true);
            navigate(-1);
            
        }catch(err){

            // setUserId('');
            // setName('');
            // setPhoneNumber('');
            // setDateOfJoining('');
            // setDepartment('');
            setSuccess(false);
            setIsLoading(false);
            if(!err?.response){
                setErrMsg('No Server Response')
            }else{
                setErrMsg('Failed adding worker!');
            }
        }
    }

    return ( 
        
        <div>
            <div className='heading'> Update Roster</div>
            <div className="heading2">{worker.name} - {worker.userId} - {worker.department}</div> 
            <form className='addWorkerTable' onSubmit={handleSubmit}>
            <table id='workerTable'>
                
                <tbody>
                    <tr>
                        <td>Date*</td>
                        <td>
                            <input  
                                type='date'
                                required
                                value={date}
                                onChange={event => {
                                    setDate(event.target.value);
                                }}
                            />
                        </td>
                       
                    </tr>
                    <tr>
                        <td>Shift Starts*</td>
                        <td>
                            <input  
                                type='time'
                                required
                                value={shiftFrom}
                                onChange={event => {
                                    setShiftFrom(event.target.value);
                                }}
                            />
                        </td>
                        <td>Shift Ends*</td>
                        <td>
                            <input  
                                type='time'
                                required
                                value={shiftTo}
                                onChange={event => {
                                    setShiftTo(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    
                </tbody>
            </table>
            {isLoading? <Loader/> : <button className='block'>Save</button>}
            {!success && <div className='error'>{err} </div>} 
            {success && <div className='success'>Roster Updated Successfully!</div>}
            </form>
        </div>
     );
     
}
 
export default UpdateRoster;