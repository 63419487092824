import {useParams, useNavigate} from 'react-router-dom';
import {useQuery} from 'react-query';
import {useState, useEffect} from 'react';
import api from '../../contexts/BaseUrl';
import Loader from '../../components/Loader';

function ReceivedDataEdit(){

    if(localStorage.getItem('editReceived') != null){
        window.location.reload();
        localStorage.removeItem('editReceived');
    }

    const navigate = useNavigate();

    const params = useParams();
    const receivedDataId = params.receivedId;


    const getReceivedInfo = async () => {
        const {data} = await api.get(`/api/receiveddates/get-individual-received?id=${params.receivedId}`)
        return data;
    }
    const {data} = useQuery("userInfo", getReceivedInfo);
    let receivedData = data;

   const [quantity, setQuantity] = useState(receivedData.quantity);
   const [container, setContainer] = useState(receivedData.container);

    const [success, setSuccess] = useState(null);
    const [err, setErrMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

   const handleSubmit =  async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try{
        const response = await api.post(`api/receiveddates/edit?Id=${params.receivedId}&Date=${receivedData.date}&Quantity=${quantity}&Container=${container}`,
            {
                headers: {
                    "Content-Type" : "application/json"
                }
            });

        setSuccess(true);
        setIsLoading(false);
        navigate(-1);
        
    }catch(err){

        setSuccess(false);
        setIsLoading(false);
        if(!err?.response){
            setErrMsg('No Server Response')
        }else{
            setErrMsg('Failed Updating!');
        }
    }
}

    return(
        <div>
            <div className='heading'>Update Received Data</div>
            <form className='login' onSubmit={handleSubmit}>
                <label style={{ fontWeight: '900', fontSize: '18px', padding: '10px' }}>Id</label>
                <label style={{ paddingLeft: '10px' }}>{receivedData.id}</label>
                <label style={{ fontWeight: '900', fontSize: '18px', padding: '10px' }}>Date</label>
                <label style={{ paddingLeft: '10px' }}>{receivedData.date}</label>
                <label style={{ fontWeight: '900', fontSize: '18px', padding: '10px' }}>Container</label>
                <label>
                    <input 
                        type='text'
                        value= {container}
                        onChange={(event) => setContainer(event.target.value)}
                    />
                </label>
                <label style={{ fontWeight: '900', fontSize: '18px', padding: '10px' }}>Quantity</label>
                <label>
                    <input 
                        type='number'
                        value= {quantity}
                        onChange={(event) => setQuantity(event.target.value)}
                    />
                </label>

                {isLoading ? <Loader /> : <button style={{ width: '200px', fontWeight:'500', fontSize: '18px' }}>Save</button>}
                {!success && <div className='error'>{err} </div>}
            </form>

        </div>
    )
}

export default ReceivedDataEdit;