import {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import api from '../../contexts/BaseUrl';
import Loader from '../../components/Loader';
import {useQuery} from 'react-query';

const getAllSectionsInfo = async () => {
    const {data} = await api.get('/api/sections/all-sections');
    return data;
}

function OutData(){

    const navigate = useNavigate();

    let params = useParams();
    var articleItemId = params.id;


    const [dateValue, setDateValue] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [section, setSection] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [err, setErrMsg] = useState('');

    const allSections = useQuery('sectionInfo', getAllSectionsInfo);
    const sections = allSections.data;


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try{
            const response = await api.post(`/api/outdates/create-outdate?Date=${dateValue}&Quantity=${quantity}&Section=${section}&articleItemId=${articleItemId}`,
            {
                headers : {
                    'Content-Type' : 'application/json'
                }
            })

            setDateValue('');
            setQuantity('');
            setSuccess(true);
            setIsLoading(false);
            navigate('update');
        }catch(err){
            setErrMsg('Failed adding out data!')
        }
    }


    return(   
        <div>       
            <div className='heading'> Add Out Date</div>
            <form className='login' onSubmit={handleSubmit}>
            <label>Out Date</label>
            <input 
                type='date'
                required
                value={dateValue}
                onChange={(event) => setDateValue(event.target.value) }
            />
            <label>Out Quantity</label>
            <input 
                type='number'
                required
                value={quantity}
                onChange={(event) => setQuantity(event.target.value) }
            />
             <label>Section</label>
             
            <label>
                <select style={{ width:'100%', padding:'10px', marginTop:'10px', border: '2px solid #f2f2f2', borderRadius:'5px' }} required onChange={(event) => setSection(event.target.value)}>
                <option key={1} value=''>Choose Section</option>
                {sections.map( (item,key) => 
                    <option key={key} value={item}>{item}</option>
                    )}
                </select>
            </label>
                        
           
            {isLoading ? <Loader /> : <button id='addButton'>Save</button>}
            {!success && <div className='error'>{err} </div>} 
            {success && <div className='success'>Out Data Added Successfully!</div>}
            </form>
         
        </div>
        
    )
}

export default OutData;