import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import api from '../../contexts/BaseUrl';
import '../Styles/AddWorker.css';
import Loader from '../../components/Loader';

const AddWorker = () => {

    const [userId, setUserId] = useState('');
    const [name, setName] = useState('')
    const [phoneNumber, setPhoneNumber] =useState('');
    const [dateOfJoining, setDateOfJoining] = useState('');
    const [department, setDepartment] = useState('');
    const [lengthOfService, setLengthOfService] = useState('');
    const [dateOfPermanent, setDateOfPermanent] = useState('');
    const [designation, setDesignation] = useState('');
    const [subDesignation, setSubDesignation] = useState('');
    const [section, setSection] = useState('');
    const [subSection, setSubSection] = useState('');
    const [line, setLine] = useState('');
    const [shift, setShift] = useState('');
    const [gender, setGender] = useState('');
    const [employmentStatus, setEmploymentStatus] = useState('');
    const [nid, setNid] = useState('');
    const [presentAddress, setPresentAddress] = useState('');
    const [permanentAddress, setPermanentAddress] = useState('');
    const [fatherHusbandName, setFatherHusbandName] = useState('');
    const [motherName, setMotherName] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [age, setAge] = useState('');
    const [maritalStatus, setMaritalStatus] = useState('');
    const [religion, setReligion] = useState('');
    const [bloodGroup, setBloodGroup] = useState('');

    const [success, setSuccess] = useState(null);
    const [err, setErrMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const handleSubmit =  async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try{
            const response = await api.post('api/workers/create-worker',
                {
                  userId : userId,
                  name: name,
                  phoneNumber: phoneNumber,
                  dateOfJoining: dateOfJoining,
                  dateOfPermanent: dateOfPermanent,
                  lengthOfService:lengthOfService,
                  department: department,
                  designation: designation,
                  subDesignation: subDesignation,
                  section: section,
                  subSection: subSection,
                  line: line,
                  shift: shift,
                  gender: gender,
                  employmentStatus: employmentStatus,
                  nid: nid,
                  presentAddress: presentAddress,
                  permanentAddress: permanentAddress,
                  age: age,
                  maritalStatus: maritalStatus,
                  religion: religion,
                  bloodGroup: bloodGroup,
                  fatherHusbandName: fatherHusbandName,
                  motherName: motherName,
                  birthDate: birthDate,
                  rosters: [] 
                },
                {
                    headers: {
                        "Content-Type" : "application/json"
                    }
                });

            setUserId('');
            setName('');
            setPhoneNumber('');
            setDateOfJoining('');
            setDepartment('');
            setSuccess(true);
            setIsLoading(false);
            navigate(-1);
            
        }catch(err){

            // setUserId('');
            // setName('');
            // setPhoneNumber('');
            // setDateOfJoining('');
            // setDepartment('');
            setSuccess(false)
            setIsLoading(false);
            if(!err?.response){
                setErrMsg('No Server Response')
            }else{
                setErrMsg('Failed adding worker!');
            }
        }
    }

    return ( 
        
        <div>
            <div className='heading'> Add New Employee</div>
            <form className='addWorkerTable' onSubmit={handleSubmit}>
            <table id='workerTable'>
                <tbody>
                    <tr>
                        <td>Name*</td>
                        <td>
                            <input  
                                type='text'
                                required
                                value={name}
                                onChange={event => {
                                    setName(event.target.value);
                                }}
                            />
                        </td>
                        <td>Employee ID*</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={userId}
                                onChange={event => {
                                    setUserId(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Phone Number*</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={phoneNumber}
                                onChange={event => {
                                    setPhoneNumber(event.target.value);
                                }}
                            />
                        </td>
                        <td>Joining Date*</td>
                        <td>
                            <input  
                                type='date'
                                required
                                value={dateOfJoining}
                                onChange={event => {
                                    setDateOfJoining(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Service Length</td>
                        <td>
                            <input  
                                type='text'
                                value={lengthOfService}
                                onChange={event => {
                                    setLengthOfService(event.target.value);
                                }}
                            />
                        </td>
                        <td>Permanent Date</td>
                        <td>
                            <input  
                                type='date'
                                value={dateOfPermanent}
                                onChange={event => {
                                    setDateOfPermanent(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Designation</td>
                        <td>
                            <input  
                                type='text'
                                value={designation}
                                onChange={event => {
                                    setDesignation(event.target.value);
                                }}
                            />
                        </td>
                        <td>Sub Designation</td>
                        <td>
                            <input  
                                type='text'
                                value={subDesignation}
                                onChange={event => {
                                    setSubDesignation(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Department</td>
                        <td>
                            <input 
                                type='text'
                                value={department}
                                onChange={event => {
                                    setDepartment(event.target.value);
                                }}
                            />
                        </td>     
                    </tr>
                    <tr>
                        <td>Section</td>
                        <td>
                            <input  
                                type='text'
                                value={section}
                                onChange={event => {
                                    setSection(event.target.value);
                                }}
                            />
                        </td>
                        <td>Sub Section</td>
                        <td>
                            <input  
                                type='text'
                                value={subSection}
                                onChange={event => {
                                    setSubSection(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Line</td>
                        <td>
                            <input  
                                type='text'
                                value={line}
                                onChange={event => {
                                    setLine(event.target.value);
                                }}
                            />
                        </td>
                        <td>Shift</td>
                        <td>
                            <input  
                                type='text'
                                value={shift}
                                onChange={event => {
                                    setShift(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Gender</td>
                        <td>
                            <input  
                                type='text'
                                value={gender}
                                onChange={event => {
                                    setGender(event.target.value);
                                }}
                            />
                        </td>
                        <td>Employment Status</td>
                        <td>
                            <input  
                                type='text'
                                value={employmentStatus}
                                onChange={event => {
                                    setEmploymentStatus(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>NID/Birth Rego</td>
                        <td>
                            <input  
                                type='text'
                                value={nid}
                                onChange={event => {
                                    setNid(event.target.value);
                                }}
                            />
                        </td>
                        <td>Age</td>
                        <td>
                            <input  
                                type='text'
                                value={age}
                                onChange={event => {
                                    setAge(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Father/Husband Name</td>
                        <td>
                            <input  
                                type='text'
                                value={fatherHusbandName}
                                onChange={event => {
                                    setFatherHusbandName(event.target.value);
                                }}
                            />
                        </td>
                        <td>Mother's Name</td>
                        <td>
                            <input  
                                type='text'
                                value={motherName}
                                onChange={event => {
                                    setMotherName(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Religion</td>
                        <td>
                            <input  
                                type='text'
                                value={religion}
                                onChange={event => {
                                    setReligion(event.target.value);
                                }}
                            />
                        </td>
                        <td>Marital Status</td>
                        <td>
                            <input  
                                type='text'
                                value={maritalStatus}
                                onChange={event => {
                                    setMaritalStatus(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Blood Group</td>
                        <td>
                            <input  
                                type='text'
                                value={bloodGroup}
                                onChange={event => {
                                    setBloodGroup(event.target.value);
                                }}
                            />
                        </td>
                        <td>Birth Date</td>
                        <td>
                            <input 
                                type='date'
                                value={birthDate}
                                onChange={event => {
                                    setBirthDate(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                    <td>Present Address</td>
                        <td>
                            <textarea 
                                value={presentAddress}
                                onChange={event => {
                                    setPresentAddress(event.target.value);
                                }}
                            />
                        </td>
                        <td>Permanent Address</td>
                        <td>
                            <textarea 
                                value={permanentAddress}
                                onChange={event => {
                                    setPermanentAddress(event.target.value);
                                }}
                            />
                        </td>
                    
                    </tr>
                </tbody>
            </table>
            {isLoading ? <Loader /> : <button className='block'>Save</button>}
            {!success && <div className='error'>{err} </div>} 
            {success && <div className='success'>Worker Added Successfully!</div>}
            </form>
        </div>
     );
     
}
 
export default AddWorker;

{/* <form className='addWorker' onSubmit={handleSubmit}>
<label>User ID</label>
<input
    type='number'
    required
    value={userId}
    onChange={event => {
        setUserId(event.target.value);
    }}
/>
<label>Full Name</label>
<input  
    type='text'
    required
    value={name}
    onChange={event => {
        setName(event.target.value);
    }}
/>
<label>Phone Number</label>
<input
    type='number'
    required
    value={phoneNumber}
    onChange={event => {
        setPhoneNumber(event.target.value);
    }}
/>
<label>Joining Date</label>
<input 
    type='date'
    required
    value={dateOfJoining}
    onChange={event => {
        setDateOfJoining(event.target.value)
    }}
/>
<label>Department</label>
<input 
    type='text'
    required
    value={department}
    onChange={event => {
        setDepartment(event.target.value);
    }}
/>

<button className='block'>Save</button>

{!success && <div className='error'>{err} </div>} 
{success && <div className='success'>Worker Added Successfully!</div>}

</form> */}