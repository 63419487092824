import {useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {useQuery} from 'react-query';
import api from '../../contexts/BaseUrl';
import Loader from '../../components/Loader';
import '../Styles/Worker.css';
import '../Styles/AddWorker.css';


function UpdatePayrollDetails(){

    let params = useParams();
    var payrollId = params.id;


    const getPayrollInfo = async () => {
        const {data} = await api.get(`/api/payrollsmonthly/get-payroll/${payrollId}`)
        return data;
    }
    const {data} = useQuery("userInfo", getPayrollInfo);
    let worker = data;

    const navigate = useNavigate();

    const [employeeName, setEmployeeName] = useState(worker.employeeName);
    const [employeeId, setEmployeeId] = useState(worker.employeeId);
    const [joiningDate, setJoiningDate] = useState(worker.joiningDate);
    const [timePeriod, setTimePeriod] = useState(worker.timePeriod);
    const [grossSalary, setGrossSalary] = useState(worker.grossSalary);
    const [basicSalary, setBasicSalary] = useState(worker.basicSalary);
    const [houseRent, setHouseRent] = useState(worker.houseRent);
    const [mlBenefits, setMlBenefits] = useState(worker.mlBenefits);
    const [attendanceBonus, setAttendanceBonus] = useState(worker.attendanceBonus);
    const [others, setOthers] = useState(worker.others);
    const [totalAbsentDays, setTotalAbsentDays] = useState(worker.totalAbsentDays);
    const [totalAttendDays, setTotalAttendDays] = useState(worker.totalAttendDays)
    const [totalLeaveDays, setTotalLeaveDays] = useState(worker.totalLeaveDays);
    const [noPayLeaveDays, setNoPayLeaveDays] = useState(worker.noPayLeaveDays);
    const [totalHolidays, setTotalHolidays] = useState(worker.totalHolidays);
    const [totalPayDays, setTotalPayDays] = useState(worker.totalPayDays);
    const [totalOverTimeHours, setTotalOverTimeHours] = useState(worker.totalOverTimeHours);
    const [totalEarlyLeave, setTotalEarlyLeave] = useState(worker.totalEarlyLeave);
    const [overTimeRate, setOverTimeRate] = useState(worker.overTimeRate);
    const [overTimeAmount, setOverTimeAmount] = useState(worker.overTimeAmount);
    const [advanceDeduction, setAdvanceDeduction] = useState(worker.advanceDeduction);
    const [taxDeduction, setTaxDeduction] = useState(worker.taxDeduction);
    const [othersDeduction, setOthersDeduction] = useState(worker.othersDeduction);
    const [totalDeduction, setTotalDeduction] = useState(worker.totalDeduction);
    const [earning, setEarning] = useState(worker.earning);
    const [arrear, setArrear] = useState(worker.arrear);
    const [totalSalary, setTotalSalary] = useState(worker.totalSalary);
    const [totalEarnings, setTotalEarnings] = useState(worker.totalEarnings);
    const [remarks, setRemarks] = useState(worker.remarks);
    const [netPay, setNetPay] = useState(worker.netPay);
    const [lateDays, setLateDays] = useState(worker.lateDays);
    
    const [success, setSuccess] = useState(null);
    const [err, setErrMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try{
            await api.post(`api/payrollsmonthly/edit/${payrollId}`,
            {
                id: payrollId,
                employeeName: employeeName,
                employeeId: employeeId,
                joiningDate: joiningDate,
                timePeriod:timePeriod,
                grossSalary: grossSalary,
                basicSalary: basicSalary,
                houseRent: houseRent,
                mlBenefits: mlBenefits,
                attendanceBonus: attendanceBonus,
                others: others,
                totalAbsentDays: totalAbsentDays,
                totalAttendDays: totalAttendDays,
                totalLeaveDays: totalLeaveDays,
                noPayLeaveDays: noPayLeaveDays,
                totalHolidays: totalHolidays,
                totalPayDays: totalPayDays,
                totalOverTimeHours: totalOverTimeHours,
                totalEarlyLeave: totalEarlyLeave,
                overTimeRate: overTimeRate,
                overTimeAmount: overTimeAmount,
                advanceDeduction: advanceDeduction,
                taxDeduction: taxDeduction,
                othersDeduction: othersDeduction,
                totalDeduction: totalDeduction,
                earning: earning,
                arrear: arrear,
                totalSalary: totalSalary,
                totalEarnings: totalEarnings,
                remarks: remarks,
                netPay: netPay,
                lateDays: lateDays
            },
            {
                headers: {
                    "Content-Type" : "application/json"
                }
            });
            setSuccess(true);
            setIsLoading(false);
            navigate(-1);
        }catch(err){

            setSuccess(false);
            setIsLoading(false);
            if(!err?.response){
                setErrMsg('No Server Response')
            }else{
                setErrMsg('Failed Updating!');
            }
        }
    }

    return(
        <div>
            <div className='heading'>Update Payroll Details</div>
            {/* <button onClick={() => console.log(worker)}>See Details</button> */}
            <form className='addWorkerTable' onSubmit={handleSubmit}>
            <table id='workerTable'>
                <tbody>
                    <tr>
                        <td id='title'>Name</td>
                        <td>
                          {worker.employeeName}
                        </td>
                        <td id='title'>Employee ID</td>
                        <td>
                            {worker.employeeId}
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Joining Date</td>
                        <td>
                           {worker.joiningDate}
                        </td>
                        <td id='title'>Time Period</td>
                        <td>
                            {worker.timePeriod}
                        </td>   
                    </tr>
                    <tr>
                        <td id='title'>Gross Salary</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={grossSalary}
                                onChange={event => {
                                    setGrossSalary(event.target.value);
                                }}
                            />
                        </td>
                        <td id='title'>Basic Salary</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={basicSalary}
                                onChange={event => {
                                    setBasicSalary(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td id='title2'>Benefits</td>
                    </tr>
                    <tr>
                        <td id='title'>House Rent</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={houseRent}
                                onChange={event => {
                                    setHouseRent(event.target.value);
                                }}
                            />
                        </td>
                        <td id='title'>Maternity Leave Benefits</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={mlBenefits}
                                onChange={event => {
                                    setMlBenefits(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Attendance Bonus</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={attendanceBonus}
                                onChange={event => {
                                    setAttendanceBonus(event.target.value);
                                }}
                            />
                        </td>
                        <td id='title'>Others</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={others}
                                onChange={event => {
                                    setOthers(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td id='title2'>Absent/Leave</td>
                    </tr>
                    <tr>
                        <td id='title'>Absent Days</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={totalAbsentDays}
                                onChange={event => {
                                    setTotalAbsentDays(event.target.value);
                                }}
                            />
                        </td>
                        <td id='title'>Attend Days</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={totalAttendDays}
                                onChange={event => {
                                    setTotalAttendDays(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Leave Days</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={totalLeaveDays}
                                onChange={event => {
                                    setTotalLeaveDays(event.target.value);
                                }}
                            />
                        </td>
                        <td id='title'>No Pay Leave Days</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={noPayLeaveDays}
                                onChange={event => {
                                    setNoPayLeaveDays(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Total Holidays</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={totalHolidays}
                                onChange={event => {
                                    setTotalHolidays(event.target.value);
                                }}
                            />
                        </td>
                        <td id='title'>Late Days</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={lateDays}
                                onChange={event => {
                                    setLateDays(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Total Pay Days</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={totalPayDays}
                                onChange={event => {
                                    setTotalPayDays(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td id='title2'>Overtime/Leave</td>
                    </tr>
                    <tr>
                        <td id='title'>Overtime (Hours)</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={totalOverTimeHours}
                                onChange={event => {
                                    setTotalOverTimeHours(event.target.value);
                                }}
                            />
                        </td>
                        <td id='title'>Early Leave (Minutes)</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={totalEarlyLeave}
                                onChange={event => {
                                    setTotalEarlyLeave(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Overtime Rate</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={overTimeRate}
                                onChange={event => {
                                    setOverTimeRate(event.target.value);
                                }}
                            />
                        </td>
                        <td id='title'>Overtime Amount</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={overTimeAmount}
                                onChange={event => {
                                    setOverTimeAmount(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td id='title2'>Deduction</td>
                    </tr>
                    <tr>
                        <td id='title'>Advanced Deduction</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={advanceDeduction}
                                onChange={event => {
                                    setAdvanceDeduction(event.target.value);
                                }}
                            />
                        </td>
                        <td id='title'>Tax Deduction</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={taxDeduction}
                                onChange={event => {
                                    setTaxDeduction(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Others Deduction</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={othersDeduction}
                                onChange={event => {
                                    setOthersDeduction(event.target.value);
                                }}
                            />
                        </td>
                        <td id='title'>Total Deduction</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={totalDeduction}
                                onChange={event => {
                                    setTotalDeduction(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td id='title2'>Earning / Salary</td>
                    </tr>
                    <tr>
                        <td id='title'>Earning</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={earning}
                                onChange={event => {
                                    setEarning(event.target.value);
                                }}
                            />
                        </td>
                        <td id='title'>Arrear</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={arrear}
                                onChange={event => {
                                    setArrear(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Total Salary</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={totalSalary}
                                onChange={event => {
                                    setTotalSalary(event.target.value);
                                }}
                            />
                        </td>
                        <td id='title'>Total Earnings</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={totalEarnings}
                                onChange={event => {
                                    setTotalEarnings(event.target.value);
                                }}
                            />
                        </td>
                       
                    </tr>
                    <tr>
                        <td id='title'>Remarks</td>
                        <td>
                            <textarea  
                                value={remarks}
                                onChange={event => {
                                    setRemarks(event.target.value);
                                }}
                            />
                        </td>    
                    </tr>
                    <tr>
                        <td id='title2'>Net Pay (Tk)</td>
                        <td id='title2'>
                            <input  
                                type='number'
                                required
                                value={netPay}
                                onChange={event => {
                                    setNetPay(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    
                </tbody>
            </table>
            {isLoading ? <Loader /> : <button className='block'>Update Payroll Details</button>}
           
            {!success && <div className='error'>{err} </div>}
            </form>
           
           
        </div>
    )
}

export default UpdatePayrollDetails;