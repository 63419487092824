import {useState, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import api from '../contexts/BaseUrl';
import AuthContext from '../contexts/AuthProvider';
import './Styles/ForgotPassword.css'
import Loader from '../components/Loader';


const Login = () => {

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [err, setErrMsg] = useState("");
    const [success, setSuccess] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    //const {auth, setAuth} = useContext(AuthContext);
   //const { Login, errorMessage, isError } = useContext(AuthContext);

    if(localStorage.getItem('auth') != null){
        window.location.reload();
    }
  
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try{  
            const response = await api.post(
                '/api/accounts/login', 
                JSON.stringify({email, password}),
                {
                    headers: {"Content-Type" : "application/json"},
                    
                }
            );

        
            // localStorage.setItem('user', JSON.stringify(json));
            // // setAuth(json);

            const token = response?.data?.token;
            const role = response?.data?.role;
            const displayName = response?.data?.displayName;
            // setAuth({ email, displayName, role, token  });
            localStorage.setItem('auth',JSON.stringify(response.data));
            setEmail("");
            setPassword("");
            setIsLoading(false);
            setSuccess(true);
            
        }catch(err){
            if (!err?.response) {
                setErrMsg("No Server Response");
              } else if (err.response?.status === 400) {
                setErrMsg("Missing Username or Password!");
              } else if (err.response?.status === 401) {
                setErrMsg("Unauthorized!");
              } else {
                setErrMsg("Login Failed!");
              }
            setSuccess(false);
            setIsLoading(false);
        }
      }
  //  {(values) => {Login(values.email, values.password)}
    return ( 
        
        <form className="login" 
            onSubmit={handleSubmit}
            >
            <label>Email Address: </label>
            <input
                type = "email"
                value = {email}
               // pattern=".+@blingshoesltd\.com"
                title = "Sing in with blignshoesltd.com email"
                required
                onChange={event => {
                    setEmail(event.target.value);
                }}
            />

            <label>Password: </label>
            <input
                type = "password"
                value = {password}
                required
                onChange={event => {
                    setPassword(event.target.value)
                }}
            />
            {isLoading ? <Loader /> : <button className='block'>Log In</button>}

            {/* <div className='forgotPassword' onClick={() => navigate('forgot-password')}>Forgotten Password?</div> */}
            
            {!success && <div className='error'>{err}</div>}
        </form>
     );
}
 
export default Login;