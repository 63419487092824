import {useState} from 'react';

import api from '../contexts/BaseUrl';


const Signup = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [displayname, setDisplayName] = useState('')
    const [username, setUsername] = useState('')
    const [success, setSuccess] = useState(false);
    const [ err, setErrMsg] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            const response = await api.post(
                'api/accounts/register',
                JSON.stringify({displayname, email, password, username}),
                {
                    headers: {"Content-Type" : "application/json"}
                }
            );
            setSuccess(true);
            setEmail("");
            setPassword("");
            setDisplayName("");
            setUsername("")
        }catch(err){
            if (!err?.response) {
                setErrMsg("No Server Response");
              } else if(err.response === 400){
                  setErrMsg("Email arleady exists");
              }
              else if (err.response?.status === 409) {
                setErrMsg("Username Taken");
              } else {
                setErrMsg("Registration Failed");
              }
        }
    }

    return ( 
        <form className="login" onSubmit={handleSubmit}>
            <label>Email Address: </label>
            <input
                type = "email"
                value = {email}
               // pattern=".+@blingshoesltd\.com"
                title = "Sing in with blignshoesltd.com email"
                required
                onChange={event => {
                    setEmail(event.target.value);
                }}
            />

            <label>Password: </label>
            <input
                type = "password"
                value = {password}
                required
                onChange={event => {
                    setPassword(event.target.value)
                }}
            />
            <label>Name: </label>
            <input
                type = "text"
                value = {displayname}
                onChange={e => setDisplayName(e.target.value)}
            />
            <label>Username: </label>
            <input
                type = "text"
                value = {username}
                onChange={e => setUsername(e.target.value)}
            />
            <button className='block' >Sign Up</button>

            {!success && <div className='error'>{err}</div>}

            {success && <div className='error'>Registration Successful, Please Verify Email</div>}

           
        </form>
     );
}
 
export default Signup;