import {useState} from 'react';
import {useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import api from '../../contexts/BaseUrl';
import Loader from '../../components/Loader';
import '../Styles/AddWorker.css';

const AddRoster = () => {

    let params = useParams();

    // let worker = GetDataWorkers(params.userId);

    var workerId = params.userId;

    const getWorkerInfo = async () => {
        const {data} = await api.get(`/api/workers/get-worker/${workerId}`)
        return data;
    }
    const {data} = useQuery("userInfo", getWorkerInfo);
    let worker = data;
   
    // const id = new Date(Date.now()).toUTCString();
    // const id = new Date(Date.now()).toISOString();
    const [date, setDate] = useState('');
    const [shiftFrom, setShiftFrom] = useState('');
    const [shiftTo, setShiftTo] = useState('');

    const [success, setSuccess] = useState(null);
    const [err, setErrMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const handleSubmit =  async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try{
            const response = await api.post(`api/rosters/create-roster?workerId=${params.userId}` ,
                {
                  date: date,
                  shiftFrom: shiftFrom,
                  shiftTo: shiftTo,  
                },
                {
                    headers: {
                        "Content-Type" : "application/json"
                    }
                });

            setDate('');
            setShiftFrom('');
            setShiftTo('');
            setSuccess(true);
            setIsLoading(false);
            
            
        }catch(err){

            // setUserId('');
            // setName('');
            // setPhoneNumber('');
            // setDateOfJoining('');
            // setDepartment('');
            setSuccess(false);
            setIsLoading(false);
            if(!err?.response){
                setErrMsg('No Server Response')
            }else{
                setErrMsg('Failed adding worker!');
            }
        }
    }

    return ( 
        
        <div>
            <div className='heading'> Add New Roster</div>
            <div className="heading2">{worker.name} - {worker.userId} - {worker.department}</div>
            <form className='addWorkerTable' onSubmit={handleSubmit}>
            <table id='workerTable'>
                
                <tbody>
                    <tr>
                        <td>Date*</td>
                        <td>
                            <input  
                                type='date'
                                required
                                value={date}
                                onChange={event => {
                                    setDate(event.target.value);
                                }}
                            />
                        </td>
                       
                    </tr>
                    <tr>
                        <td>Shift Starts*</td>
                        <td>
                            <input  
                                type='time'
                                required
                                value={shiftFrom}
                                onChange={event => {
                                    setShiftFrom(event.target.value);
                                }}
                            />
                        </td>
                        <td>Shift Ends*</td>
                        <td>
                            <input  
                                type='time'
                                required
                                value={shiftTo}
                                onChange={event => {
                                    setShiftTo(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    
                </tbody>
            </table>
            {isLoading ? <Loader /> : <button className='block'>Save</button>}
            {!success && <div className='error'>{err} </div>} 
            {success && <div className='success'>Roster Added Successfully!</div>}
            </form>
        </div>
     );
     
}
 
export default AddRoster;