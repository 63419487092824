import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import api from '../../contexts/BaseUrl';
import '../Styles/AddWorker.css';

const AddRosterDepartment = () => {

   
    // const id = new Date(Date.now()).toUTCString();
    // const id = new Date(Date.now()).toISOString();
    const [date, setDate] = useState('');
    const [shiftFrom, setShiftFrom] = useState('');
    const [shiftTo, setShiftTo] = useState('');

    const [dep, setDep] = useState('');

    const [success, setSuccess] = useState(null);
    const [err, setErrMsg] = useState('');
    
    const [title, setTitle] = useState('');
    const [radioDate, setRadioDate] = useState(''); 

    const navigate = useNavigate();

    
    var url;
    var y, m;
    var handleSubmit;
    
    if(radioDate==='year'){
        if(title === 'Department'){
            url = `api/rosters/create-roster-department?searchInput=${dep}&isDept=true` 
        }
        if(title === 'Section'){
            url = `api/rosters/create-roster-department?searchInput=${dep}&isSect=true`
        }
        if(title === 'Sub Section'){
            url = `api/rosters/create-roster-department?searchInput=${dep}&isSubSect=true`
        }
        if(title === 'Line'){
            url = `api/rosters/create-roster-department?searchInput=${dep}&isLine=true`
        }

        handleSubmit =  async (e) => {
            e.preventDefault();
            try{
                const response = await api.post(url ,
                    {
                      date: date,
                      shiftFrom: shiftFrom,
                      shiftTo: shiftTo,  
                    },
                    {
                        headers: {
                            "Content-Type" : "application/json"
                        }
                    });
    
                setDate('');
                setDep('');
                setShiftFrom('');
                setShiftTo('');
                setSuccess(true);
                navigate(-1);
                
                
            }catch(err){
    
                // setUserId('');
                // setName('');
                // setPhoneNumber('');
                // setDateOfJoining('');
                // setDepartment('');
                setSuccess(false)
                if(!err?.response){
                    setErrMsg('No Server Response')
                }else{
                    setErrMsg('Failed adding roster!');
                }
            }
        }
    }else if(radioDate==='monthyear'){
         y = parseInt(date.substring(0,4));
         m = parseInt(date.substring(5));

         if(title === 'Department'){
            url = `api/rosters/create-roster-department-month?searchInput=${dep}&month=${m}&year=${y}&isDept=true`
         }
         if(title === 'Section'){
            url = `api/rosters/create-roster-department-month?searchInput=${dep}&month=${m}&year=${y}&isSect=true`
         }
         if(title === 'Sub Section'){
            url = `api/rosters/create-roster-department-month?searchInput=${dep}&month=${m}&year=${y}&isSubSect=true`
         }
         if(title === 'Line'){
            url = `api/rosters/create-roster-department-month?searchInput=${dep}&month=${m}&year=${y}&isLine=true`
         }

        

         handleSubmit =  async (e) => {
            e.preventDefault();
            try{
                const response = await api.post(url ,
                    {
                      shiftFrom: shiftFrom,
                      shiftTo: shiftTo,  
                    },
                    {
                        headers: {
                            "Content-Type" : "application/json"
                        }
                    });
    
                setDate('');
                setDep('');
                setShiftFrom('');
                setShiftTo('');
                setSuccess(true);
                navigate(-1);
                
                
            }catch(err){
    
                // setUserId('');
                // setName('');
                // setPhoneNumber('');
                // setDateOfJoining('');
                // setDepartment('');
                setSuccess(false)
                if(!err?.response){
                    setErrMsg('No Server Response')
                }else{
                    setErrMsg('Failed adding roster!');
                }
            }
        }
    }
    

    return ( 
        
        <div>
            <div className='heading'> Add Roster By Category</div>
            <form className='addWorkerTable' onSubmit={handleSubmit}>
            <table id='workerTable'>
                
                <tbody>
                    <tr>
                        <td>
                            Department <br /> Section <br /> Sub Section <br /> Line
                        </td>
                        <td>
                            <input 
                                type="radio"
                                required
                                name="title"
                                value="Department"
                                onChange={(event) => setTitle(event.target.value)}
                                checked={title === "Department"}
                            />
                            <input 
                                type="radio"
                                required
                                name="title"
                                value="Section"
                                onChange={(event) => setTitle(event.target.value)}
                                checked={title === "Section"}
                            />
                             <input 
                                type="radio"
                                required
                                name="title"
                                value="Sub Section"
                                onChange={(event) => setTitle(event.target.value)}
                                checked={title === "Sub Section"}
                            />
                             <input 
                                type="radio"
                                required
                                name="title"
                                value="Line"
                                onChange={(event) => setTitle(event.target.value)}
                                checked={title === "Line"}
                            />
                        </td>
                         
                        <td>
                            Date <br/>Month Year
                        </td>
                        <td>
                            
                                <input type="radio" required name="date" value='year'
                                    onChange={(event) => setRadioDate(event.target.value)}
                                    checked={radioDate === 'year'}
                                />    
                                <input type="radio" required name="date" value='monthyear'
                                    onChange={(event) => setRadioDate(event.target.value)}
                                    checked={radioDate === 'monthyear'}
                                />
                            
                        </td>
                        
                    </tr>
               
            {(radioDate==='year' && title.length>2) &&   
                   
                    <tr>
                       <td>{title}*</td>
                            <td>
                                <input  
                                    type='text'
                                    required
                                    value={dep}
                                    onChange={event => {
                                        setDep(event.target.value);
                                    }}
                                />
                            </td>
                        <td>Date*</td>
                        <td>
                            <input  
                                type='date'
                                required
                                value={date}
                                onChange={event => {
                                    setDate(event.target.value);
                                }}
                            />
                        </td>
                    </tr>}
           
            {(radioDate === 'monthyear' && title.length>2) &&
                    <tr>
                        <td>{title}*</td>
                            <td>
                                <input  
                                    type='text'
                                    required
                                    value={dep}
                                    onChange={event => {
                                        setDep(event.target.value);
                                    }}
                                />
                            </td>
                        <td>Month Year</td>
                        <td>
                            <input  
                                type='month'
                                required
                                value={date}
                                onChange={event => {
                                    setDate(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
            }
                    <tr>
                        <td>Shift Starts*</td>
                        <td>
                            <input  
                                type='time'
                                required
                                value={shiftFrom}
                                onChange={event => {
                                    setShiftFrom(event.target.value);
                                }}
                            />
                        </td>
                        <td>Shift Ends*</td>
                        <td>
                            <input  
                                type='time'
                                required
                                value={shiftTo}
                                onChange={event => {
                                    setShiftTo(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    
                </tbody>
            </table>
            <button className='block'>Save</button>
            {!success && <div className='error'>{err} </div>} 
            {success && <div className='success'>Roster Added Successfully!</div>}
            </form>
        </div>
     );
     
}
 
export default AddRosterDepartment;