import { Dropdown } from 'react-dropdown-now';
import './Styles/Card.css';

function Card(props){

    return(
        <div className='cardContent'>
            <span id='cardContent1'>Email</span>
            <span id='cardContent2'>{props.email} </span>
            <span id='cardContent3'>Name</span>
            <span id='cardContent4'>{props.name} </span>
            <span id='cardContent5'>Role</span>
            <span id='cardContent6'>{props.role} </span>
            {props.dropdown === 'true' && 
                <Dropdown 
                    placeholder={props.placeholder}
                    options={props.options}
                    value={props.value}
                    onChange={props.onChange}
                    className='dropDown'
                />
            }
            {props.button === 'true' &&
                <button className='cardButton' onClick={props.onClick}>{props.buttonTitle}</button>
            }
            {props.success === 'true' && <div className='cardSuccess'>Role has been changed Successfully!</div>}
            {props.success=== 'false' && <div className='cardError'>Failed changing role!</div>}
        </div>
    );
    
}

export default Card;