import {useState} from 'react';
import {useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import api from '../../contexts/BaseUrl';
import Loader from '../../components/Loader';
import '../Styles/AddBuyer.css';

const AddAttendance = () => {
    
    const params = useParams();
    const title = params.buyer;
    const [titleValue, setTitleValue] = useState('');
    
    

    const [success, setSuccess] = useState(null);
    const [err, setErrMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    var url;
    if(title === 'Buyer'){
        url = `api/buyers/create-buyer?buyerName=${titleValue}`
    }else if(title === 'Unit'){
        url = `api/itemunits/create-itemUnit?unitName=${titleValue}`
    }else if(title === 'Color'){
        url = `api/itemcolors/create-color?colorName=${titleValue}`
    }else{
        url = `api/sections/create-section?sectionName=${titleValue}`
    }
    const handleSubmit =  async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try{
            const response = await api.post(url,
            {
                headers: {
                    "Content-Type" : "application/json"
                }
            });

       setTitleValue('')
       setIsLoading(false);
       setSuccess(true);  
       navigate('/');   
            
        }catch(err){

            // setUserId('');
            // setName('');
            // setPhoneNumber('');
            // setDateOfJoining('');
            // setDepartment('');
            setSuccess(false);
            setIsLoading(false);
            setTitleValue('');
            if(!err?.response){
                setErrMsg('No Server Response')
            }else if(err.response.status === 400){
                setErrMsg('Already exist !');
            }else{
                setErrMsg("Failed")
            }
            
        }
    }

    return ( 
        
        <div>
            <div className='heading'> Add New {title}</div>
            
            <form className='addBuyer' onSubmit={handleSubmit}>
            <label>{title} Name:</label>
            <input 
                type='text'
                required
                value={titleValue}
                onChange={(event) => setTitleValue(event.target.value) }
            />
            {isLoading ? <Loader /> : <button id='addButton'>Save</button>}
            {!success && <div className='error'>{err} </div>} 
            {success && <div className='success'>{title} Added Successfully!</div>}
            </form>
        </div>
     );
     
}
 
export default AddAttendance;