import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from './contexts/AuthProvider';
import {QueryClientProvider, QueryClient} from 'react-query';
import Loading from './components/Loading';

const root = ReactDOM.createRoot(document.getElementById('root'));

const client = new QueryClient({
  defaultOptions: {
    queries: {
        suspense: true,
    },
  },
});

root.render(
  // <React.StrictMode>
  <QueryClientProvider client={client}>
    <AuthProvider>
      <Suspense fallback={<Loading />}>
        <App />
      </Suspense>
   
    </AuthProvider>
  </QueryClientProvider>
   
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
