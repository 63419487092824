import {BrowserRouter as Router,Routes, Route, Navigate, Outlet} from 'react-router-dom';
import {useContext} from 'react';
//import {AuthContext} from './contexts/Auth';
//import Auth from './contexts/Auth'

import AuthContext from './contexts/AuthProvider';

import Login from './pages/Login';
import Signup from './pages/Signup';
import MainHome from './pages/MainHome';
import Home from './pages/Home';
import AdminHome from './pages/AdminHome';
import Navbar from './components/Navbar';
import UserDetails from './pages/UserDetails';
import ChangePassword from './pages/ChangePassword';
import AddWorker from './pages/HRStaff/AddWorker';
import Worker from './pages/HRStaff/Worker';
import UpdateWorker from './pages/HRStaff/UpdateWorker';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import User from './pages/Admin/User';
import Roster from './pages/HRStaff/Roster';
import RosterDetails from './pages/HRStaff/RosterDetails';
import AddRoster from './pages/HRStaff/AddRoster';
import UpdateRoster from './pages/HRStaff/UpdateRoster';
import AddRosterDepartment from './pages/HRStaff/AddRosterDeparment';
import Attendance from './pages/HRStaff/Attendance';
import AddAttendance from './pages/HRStaff/AddAttendance';
import AttendanceEdit from './pages/HRStaff/AttendanceEdit';
import Payroll from './pages/HRStaff/Payroll';
import PayrollDetails from './pages/HRStaff/PayrollDetails';
import IndividualPayroll from './pages/HRStaff/IndividualPayroll';
import AddIndividualPayroll from './pages/HRStaff/AddIndividualPayroll';
import EditIndividualPayroll from './pages/HRStaff/EditIndividualPayroll';
import AddMonthlyPayroll from './pages/HRStaff/AddMonthlyPayroll';
import UpdatePayrollDetails from './pages/HRStaff/UpdatePayrollDetails';
import StoreHome from './pages/Store/StoreHome';
import PurchaseOrder from './pages/Store/PurchaseOrder';
import PurchaseOrderDetails from './pages/Store/PurchaseOrderArticle';
import AddProperty from './pages/Store/AddProperty';
import AddBuyer from './pages/Store/AddBuyer';
import ArticleItemDetails from './pages/Store/ArticleItemDetails';
import UpdateArticleItem from './pages/Store/UpdateArticleItem';
import AddPurchaseOrder from './pages/Store/AddPurchaseOrder';
import AddArticle from './pages/Store/AddArticle';
import AddItem from './pages/Store/AddItem';
import ReceivedData from './pages/Store/ReceivedData';
import OutData from './pages/Store/OutData';
import ReceivedDataUpdate from './pages/Store/ReceivedDataUpdate';
import OutDataUpdate from './pages/Store/OutDataUpdate';
import ReceivedDataEdit from './pages/Store/ReceivedDataEdit';
import OutDataEdit from './pages/Store/OutDataEdit';
import DeletePurchaseOrder from './pages/Store/DeletePurchaseOrder';
import DeleteArticle from './pages/Store/DeleteArticle';
import DirectorHome from './pages/Director/DirectorHome';
import DirectorStoreHome from './pages/Director/Store/StoreHome';
import DirectorePurchaseOrder from './pages/Director/Store/PurchaseOrder';
import DirectorPurchaseOrderArticle from './pages/Director/Store/PurchaseOrderArticle';

function App() {

  // const {auth} = useContext(AuthContext);

  // const user = JSON.stringify(auth) === '{}';

  const user = localStorage.getItem('auth');
  const userObj = JSON.parse(user);


  return (
    
    <Router>
      <Navbar />
      <div className="App">
         <Routes>
          
            <Route path='/' element={(user == null) ?  <Login /> : <Navigate to ='/admin' />} />
            
            <Route path='admin' element ={ (user != null && userObj.role === 'Admin' ) ? <Outlet /> : <Navigate to = '/hr-staff' />}>
              <Route path='' element={<AdminHome />} />
              <Route path='register' element={<Signup />} />
              <Route path=':id' element={<User />} />
              <Route path='user-details' element={<UserDetails />} />
              <Route path='user-details/change-password' element={<ChangePassword />} />
            </Route>

            <Route path='hr-staff' element ={ (user != null && userObj.role === 'HRStaff') ? <Outlet /> : <Navigate to = '/store' />}>
              <Route path='' element={<MainHome />} />
              <Route path = 'home' element={<Home />} />
              <Route path='worker/:userId' element={<Worker />} />
              <Route path='worker/:userId/edit' element={<UpdateWorker />} />
              <Route path='user-details' element={ <UserDetails  />} />
              <Route path='user-details/change-password' element={<ChangePassword />} />
              <Route path='add-worker' element={<AddWorker />} /> 
              <Route path='roster' element={<Roster />} />
              <Route path = 'roster/department' element={<AddRosterDepartment />} />
              <Route path='roster/:userId' element={<RosterDetails />} />
              <Route path='roster/:userId/add' element={<AddRoster />} />
              <Route path='roster/:userId/:rosterId' element={<UpdateRoster />} />
              <Route path='attendance' element={<Attendance />} />
              <Route path='add-attendance' element={<AddAttendance />} />
              <Route path= 'attendance/:id' element={<AttendanceEdit />} />
              <Route path='payroll' element={<Payroll />} />
              <Route path='payroll/:id' element={<PayrollDetails />} />
              <Route path='payroll/:id/edit' element={<UpdatePayrollDetails />} />
              <Route path='payroll/individual' element={<IndividualPayroll />} />
              <Route path='payroll/individual/:userId' element={<AddIndividualPayroll />} />
              <Route path='payroll/edit' element={<EditIndividualPayroll />} />
              <Route path='payroll/monthly' element={<AddMonthlyPayroll />} />
            </Route>
            <Route path='store' element={(user != null && userObj.role ==='StoreManager') ? <Outlet /> : <Navigate to='/director' />}>
              <Route path='' element={<StoreHome />} />
              <Route path='add-property' element={<AddProperty />} />
              <Route path='/store/add-property/:buyer' element={<AddBuyer />} />
              <Route path='/store/:buyerName' element={<PurchaseOrder />} />
              <Route path='/store/:buyerName/:purchaseOrderId/add-article/:articleId' element={<AddArticle />} />
              <Route path='/store/:buyerName/add-purchase/:buyerId' element={<AddPurchaseOrder />} />
              <Route path='/store/:buyerName/delete-purchase/order' element={<DeletePurchaseOrder />} />
              <Route path='/store/:buyerName/:purchaseOrderId' element={<PurchaseOrderDetails />} />
              <Route path='/store/:buyerName/:purchaseOrderId/add-item' element={<AddItem />} />
              <Route path='/store/:buyerName/:purchaseOrderId/delete-article/delete' element={<DeleteArticle />} />
              <Route path='/store/:buyerName/:purchaseOrderId/articleItemId/:id' element={<ArticleItemDetails />} />
              <Route path='/store/:buyerName/:purchaseOrderId/articleItemId/:id/received' element={<ReceivedData />}/>
              <Route path='/store/:buyerName/:purchaseOrderId/articleItemId/:id/received/update' element={<ReceivedDataUpdate />}/>
              <Route path='/store/:buyerName/:purchaseOrderId/articleItemId/:id/out' element={<OutData />}/>
              <Route path='/store/:buyerName/:purchaseOrderId/articleItemId/:id/out/update' element={<OutDataUpdate />}/>
              <Route path='/store/:buyerName/:purchaseOrderId/articleItemId/:id/edit' element={<UpdateArticleItem />} />
              <Route path='/store/:buyerName/:purchaseOrderId/articleItemId/:id/edit/:receivedId' element={<ReceivedDataEdit />} />
              <Route path='/store/:buyerName/:purchaseOrderId/articleItemId/:id/edit/outdate/:outId' element={<OutDataEdit />} />
              <Route path='user-details' element={ <UserDetails  />} />
              <Route path='user-details/change-password' element={<ChangePassword />} />
            </Route>
            <Route path='director' element={(user != null && userObj.role === 'Director') ? <Outlet /> : <Navigate to='/' />}>
              <Route path='' element={<DirectorHome />} />
              <Route path='/director/store' element={<DirectorStoreHome />} />
              <Route path='/director/store/:buyerName' element={<DirectorePurchaseOrder />} />
              <Route path='/director/store/:buyerName/:purchaseOrderId' element={<DirectorPurchaseOrderArticle />} />
              <Route path='user-details' element={ <UserDetails  />} />
              <Route path='user-details/change-password' element={<ChangePassword />} />
            </Route>
            <Route path='forgot-password' element={<ForgotPassword />} />
            <Route path='reset-password' element={<ResetPassword />} />     
        </Routes>
      </div>
    </Router>
    
      
    
    
  );
}

export default App;
