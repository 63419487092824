import {useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {useQuery} from 'react-query';
// import { GetDataWorkers} from './Data';
import CardWorker from '../../components/CardWorker';
import '../Styles/Worker.css';
import '../Styles/AddWorker.css';
import '../Styles/UpdateArticleItem.css';
import api from '../../contexts/BaseUrl';
import CustomButton from '../../components/CustomButton';
import Loader from '../../components/Loader';

const getAllColorsInfo = async () => {
    const {data} = await api.get('/api/itemcolors/all-colors');
    return data;
}

const getAllSectionsInfo = async () => {
    const {data} = await api.get('/api/sections/all-sections');
    return data;
}

const getAllUnitsInfo = async () => {
    const {data} = await api.get('/api/itemunits/get-itemunits');
    return data;
}


function UpdateArticleItem () {

    if(localStorage.getItem('edit') != null){
        window.location.reload();
        localStorage.removeItem('edit');
    }
    let params = useParams();
    var buyerName = params.buyerName;
    var articleItemId = params.id;

    const getArticleItemInfo = async () => {
        const {data} = await api.get(`/api/articleItems/get-individual-articleItem?articleItemId=${articleItemId}`)
        return data;
    }
    const {data} = useQuery("articleItemInfo", getArticleItemInfo);
    var articleItem = data;

    const allColors = useQuery("colorInfo", getAllColorsInfo);
    const colors = allColors.data;

    const allSections = useQuery('sectionInfo', getAllSectionsInfo);
    const sections = allSections.data;

    const allUnits = useQuery('unitInfo', getAllUnitsInfo);
    const units = allUnits.data;

    const [selectedColor, setSelectedColor] = useState(articleItem.itemColor);
    const [selectedUnit, setSelectedUnit] = useState(articleItem.unit)
    

    const navigate = useNavigate();
    
    const [itemName, setItemName] = useState(articleItem.itemName);
    const [receivedContainer, setReceivedContainer] = useState(articleItem.receivedContainer)
    // const [totalReceivedQuantity, setTotalReceivedQuantity] = useState(articleItem.totalReceivedQuantity)
    // const [totalOutQuantity, setTotalOutQuantity] =useState(articleItem.totalOutQuantity);
    // const [remainingQuantity, setRemainingQuantity] = useState(articleItem.remainingQuantity);
    const [remarks, setRemarks] = useState(articleItem.remarks);
    
    

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [err, setErrMsg] = useState('');

    const handleDelete =  async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try{
            const response = await api.post(`api/articleitems/delete-articleitem?id=${articleItemId}`,
                {
                    headers: {
                        "Content-Type" : "application/json"
                    }
                });

            setSuccess(true);
            setIsLoading(false);
            navigate(-2);    
        }catch(err){

            setSuccess(false);
            setIsLoading(false);
            if(!err?.response){
                setErrMsg('No Server Response')
            }else{
                setErrMsg('Failed Updating!');
            }
        }
    }
   
    const handleSubmitUpdateArticle =  async (e) => {
        e.preventDefault();
        setIsLoading(true);
        var totalReceivedQuantity = 0;
        var totalOutQuantity = 0;
        for(var i = 0; i<articleItem.receivedDates.length; i++){
            totalReceivedQuantity += articleItem.receivedDates[i].quantity;
        }
        for(var i = 0; i<articleItem.outDates.length; i++){
            totalOutQuantity += articleItem.outDates[i].quantity;
        }
        var remainingQuantity = totalReceivedQuantity - totalOutQuantity;
        try{
            const response = await 
                api.post(`api/articleItems/edit?Id=${articleItemId}&ItemName=${itemName}&ItemUnit=${articleItem.itemUnit}&ItemColor=${articleItem.itemColor}
                &TotalReceivedQuantity=${totalReceivedQuantity}
                &TotalOutQuantity=${totalOutQuantity}&RemainingQuantity=${remainingQuantity}&Remarks=${remarks}`,
               
                {
                    headers: {
                        "Content-Type" : "application/json"
                    }
                });

            setSuccess(true);
            setIsLoading(false);
            navigate(-1);
            
        }catch(err){

            setSuccess(false);
            setIsLoading(false);
            if(!err?.response){
                setErrMsg('No Server Response')
            }else{
                setErrMsg('Failed Updating!');
            }
        }
    }
    var receivedDates = articleItem.receivedDates;
    var outDates = articleItem.outDates;
    

    return ( 
        
        <div>
           
            <div className='heading' onClick={() => console.log(articleItem)}>{buyerName} Update Item Details</div>
            <div className='leftBar'>.</div>
            <div className='rightBar'>
                <CustomButton
                    title='Delete This'
                    onClick={handleDelete}
                />
            </div>
            <form className='addWorkerTable' onSubmit={handleSubmitUpdateArticle}>
            <table id='workerTable'>
                <tbody>
                    <tr>
                        <td id='title'>Item Name*:</td>
                        <td>
                            <input
                            type='text'
                            required
                            value={itemName}
                            onChange={(event) => setItemName(event.target.value)}
                            />
                        </td>
                        <td id='title'>Item ID</td>
                        <td>
                           {articleItem.id}
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Unit*</td>
                        <td>
                            {articleItem.itemUnit}
                            {/* <select id='selectSection' required onChange={(event) => setSelectedUnit(event.target.value)}>
                                <option key={1} value=''>Choose Unit</option>
                                {units.map( (item,key) => 
                                    <option key={key} value={item}>{item}</option>
                                    )}
                            </select> */}
                        </td>
                        <td id='title'>Color*</td>
                        <td>
                            {articleItem.itemColor}
                            {/* <select id='selectSection' required onChange={(event) => setSelectedColor(event.target.value)}>
                                <option key={1} value=''>Choose Color</option>
                                {colors.map( (item,key) => 
                                    <option key={key} value={item}>{item}</option>
                                    )}
                            </select> */}
                        </td>
                    </tr>
                    <tr>
                        
                        <td id='title'>Received Date*</td>
                        <td>
                            {receivedDates.map( (item,key) => 
                                <div key={key} value={item.id}>{item.date}</div> 
                            )}
                        </td>
                        <td id='title'>Received Quantity</td>
                        <td onClick={() => localStorage.setItem('editReceived', 1)}>
                            {receivedDates.map( (item,key) => 
                                <div key={key} value={item.id} id='editQuantity'>
                                    <span id='quantity'>{item.quantity}</span>
                                    <span id='editReceivedData' onClick={() => navigate(`${item.id}`)}>Edit</span>
                                </div> 
                                
                            )}
                        </td>   
                    </tr>
                    <tr>
                        <td id='title'>Received Container</td>
                        <td>
                            {receivedDates.map( (item,key) => 
                                <div key={key} value={item.id}>{item.container}</div> 
                            )}
                        </td>
                    </tr>
                    <tr>
                        
                        <td id='title'>Out Date*</td>
                        <td>
                            {outDates.map( (item,key) => 
                                <div key={key} value={item.id}>{item.date}</div> 
                            )}
                        </td>
                        <td id='title'>Out Quantity</td>
                        <td onClick={() => localStorage.setItem('editOut', 1)}>
                            {outDates.map( (item,key) => 
                                <div key={key} value={item.id} id='editQuantity'>
                                    <span id='quantity'>{item.quantity}</span>
                                    <span id='editReceivedData' onClick={() => navigate(`outdate/${item.id}`)}>Edit</span>
                                </div> 
                            )}
                        </td>
                       
                    </tr>
                    <tr>
                        <td id='title'>Out Section</td>
                        <td>
                            {outDates.map( (item,key) => 
                                <div key={key} value={item.id}>{item.section}</div> 
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Total Received Quantity</td>
                        <td>{articleItem.totalReceivedQuantity}</td>
                        <td id='title'>Total Out Quantity</td>
                        <td>{articleItem.totalOutQuantity}</td>
                        
                    </tr>
                   
                    <tr>
                    <td id='title'>Remaining Quantity</td>
                        <td>
                           {articleItem.remainingQuantity}
                        </td>
                       <td id='title'>Remarks*</td>
                       <td>
                       <input
                            type='text'
                            required
                            value= {remarks}
                            onChange={(event) => setRemarks(event.target.value)}
                            />
                       </td>
                        
                    </tr>
                  
                </tbody>
            </table>
            <button className='block'>Update Item Details</button>
            {!success && <div className='error'>{err} </div>}
            </form>
           
        </div>


        
     );
}


export default UpdateArticleItem;


