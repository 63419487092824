import {useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {useQuery} from 'react-query';
// import { GetDataWorkers} from './Data';
import CardWorker from '../../components/CardWorker';
import '../Styles/Worker.css';
import '../Styles/AddWorker.css';
import '../Styles/AddItem.css';
import api from '../../contexts/BaseUrl';

const getAllColorsInfo = async () => {
    const {data} = await api.get('/api/itemcolors/all-colors');
    return data;
}

const getAllSectionsInfo = async () => {
    const {data} = await api.get('/api/sections/all-sections');
    return data;
}

const getAllUnitsInfo = async () => {
    const {data} = await api.get('/api/itemunits/get-itemunits');
    return data;
}

function AddItem () {

    let params = useParams();
    var buyerName = params.buyerName;
    var purchaseOrderId = params.purchaseOrderId;

    const getPurchaseArticle = async () => {
        const {data} = await api.get(`/api/purchaseorders/get-individual-po/${purchaseOrderId}`)
        return data;
    }
    const {data} = useQuery("purchaseArticle", getPurchaseArticle);
    var articles = data.articles;
   
    const allColors = useQuery("colorInfo", getAllColorsInfo);
    const colors = allColors.data;

    const allSections = useQuery('sectionInfo', getAllSectionsInfo);
    const sections = allSections.data;

    const allUnits = useQuery('unitInfo', getAllUnitsInfo);
    const units = allUnits.data;
    
    

    const navigate = useNavigate();

    const [itemName, setItemName] = useState('');

    const [totalReceivedQuantity, setTotalReceivedQuantity] = useState(0)
    const [totalOutQuantity, setTotalOutQuantity] = useState(0)
    const [remarks, setRemarks] = useState('')
    const [remainingQuantity, setRemainingQuantity] = useState(0)
    const [selectedArticleId, setSelectedArticleId] = useState('');
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedSection, setSelectedSection] = useState('');
    const [selectedUnit, setSelectedUnit] = useState('')

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [err, setErrMsg] = useState('');

    const handleSubmit =  async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try{
            const response = await 
                api.post(`api/articleItems/create-articleItem?PurchaseOrderId=${purchaseOrderId}&ItemName=${itemName}&ItemUnit=${selectedUnit}&ItemColor=${selectedColor}
                    &TotalReceivedQuantity=${totalReceivedQuantity}
                    &TotalOutQuantity=${totalOutQuantity}&RemainingQuantity=${remainingQuantity}&Remarks=${remarks}&articleId=${selectedArticleId}`,
                {
                    headers: {
                        "Content-Type" : "application/json"
                    }
                });

            setSuccess(true);
            setIsLoading(false);
            setRemarks('');
            setSelectedArticleId('');

            navigate(-1);
            
        }catch(err){

            setSuccess(false);
            setIsLoading(false);
            if(!err?.response){
                setErrMsg('No Server Response')
            }else{
                setErrMsg('Failed Updating!');
            }
        }
    }

    
    
    return ( 
        <div>
            
            <div className='heading'>Add New Item</div>
            <form className='addWorkerTable' onSubmit={handleSubmit}>
            <table id='workerTable'>
                <tbody>
                    <tr>
                        <td id='title'>Item Name*</td>
                        <td>
                            <input
                            type='text'
                            required
                            value={itemName}
                            onChange={(event) => setItemName(event.target.value)}
                            />
                        </td>
                        <td>Article Number*</td>
                        <td>
                            <select required onChange={(event) => setSelectedArticleId(event.target.value)}>
                                <option key={1} value=''>Choose Article</option>
                                {articles.map( (item,key) => 
                                    <option key={key} value={item.id}>{item.articleNumber}</option>
                                    )}
                            </select>
                        </td>
                        
                        
                    </tr>
                    <tr>
                        <td id='title'>Unit*</td>
                        <td>
                            <select id='selectSection' required onChange={(event) => setSelectedUnit(event.target.value)}>
                                <option key={1} value=''>Choose Unit</option>
                                {units.map( (item,key) => 
                                    <option key={key} value={item}>{item}</option>
                                    )}
                            </select>
                        </td>
                        <td id='title'>Color*</td>
                        <td>
                            <select id='selectSection' required onChange={(event) => setSelectedColor(event.target.value)}>
                                <option key={1} value=''>Choose Color</option>
                                {colors.map( (item,key) => 
                                    <option key={key} value={item}>{item}</option>
                                    )}
                            </select>
                        </td>
                    </tr>
                    <tr>
                       
                        <td id='title'>Remarks*</td>
                        <td>
                        <input
                                type='text'
                                required
                                value= {remarks}
                                onChange={(event) => setRemarks(event.target.value)}
                                />
                        </td>
                        
                    </tr>
                </tbody>
            </table>
            <button className='block'>Add Item</button>
            {!success && <div className='error'>{err} </div>} 
            {success && <div className='success'>Item Added Successfully!</div>}
            </form>
           
           
        </div>


        
     );
}


export default AddItem;


