import {useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {useQuery} from 'react-query';
// import { GetDataWorkers} from './Data';
import CardWorker from '../../components/CardWorker';
import '../Styles/Worker.css';
import '../Styles/AddWorker.css';
import api from '../../contexts/BaseUrl';
import CustomButton from '../../components/CustomButton';

function ArticleItemDetails () {

    let params = useParams();
    var buyerName = params.buyerName;
    var articleItemId = params.id;

    const getArticleItemInfo = async () => {
        const {data} = await api.get(`/api/articleItems/get-individual-articleItem?articleItemId=${articleItemId}`)
        return data;
    }
    const {data} = useQuery("articleItemInfo", getArticleItemInfo);
    var articleItem = data;

    const navigate = useNavigate();

    const receivedDates = articleItem.receivedDates;
    const outDates = articleItem.outDates;
    
    return ( 
        <div>
            <div className='leftBar'>
                .
            </div>
            <div className='rightBar'>
                <CustomButton
                    title='Add Received Data'
                    onClick={() => navigate('received')}
                />
                <CustomButton
                    title='Add Out Data'
                    onClick={() => navigate('out')}
                />
            </div>
        <div>
            <div className='heading' onClick={() => console.log(receivedDates)}>{buyerName} Item Details</div>
            <div className='workerTable'>
            <table id='workerTable'>
                <tbody>
                    <tr>
                        <td id='title'>Item Name:</td>
                        <td>{articleItem.itemName}</td>
                        <td id='title'>Item ID</td>
                        <td>{articleItem.id} </td>
                    </tr>
                    <tr>
                        <td id='title'>Unit</td>
                        <td>
                            {articleItem.itemUnit}
                        </td>
                        <td id='title'>Color</td>
                        <td>
                           {articleItem.itemColor}
                        </td>
                    </tr>
                    
                    <tr>
                        
                        <td id='title'>Received Date</td>
                        <td>
                            {receivedDates.map( (item,key) => 
                                <div key={key} value={item.id}>{item.date}</div> 
                            )}
                        </td>
                        <td id='title'>Received Quantity</td>
                        <td>
                            {receivedDates.map( (item,key) => 
                                <div key={key} value={item.id}>{item.quantity}</div> 
                            )}
                        </td>
                       
                    </tr>
                    <tr>
                        <td id='title'>Received Container</td>
                        <td>
                            {receivedDates.map( (item,key) => 
                                <div key={key} value={item.id}>{item.container}</div> 
                            )}
                        </td>
                    </tr>
                    <tr>
                        
                        <td id='title'>Out Date</td>
                        <td>
                            {outDates.map( (item,key) => 
                                <div key={key} value={item.id}>{item.date}</div> 
                            )}
                        </td>
                        <td id='title'>Out Quantity</td>
                        <td>
                            {outDates.map( (item,key) => 
                                <div key={key} value={item.id}>{item.quantity}</div> 
                            )}
                        </td>  
                    </tr>
                    <tr>
                        <td id='title'>Out Section</td>
                        <td>
                            {outDates.map( (item,key) => 
                                <div key={key} value={item.id}>{item.section}</div> 
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td id='title'>Total Received Quantity</td>
                        <td>
                            {articleItem.totalReceivedQuantity}
                        </td>
                        <td id='title'>Total Out Quantity</td>
                        <td>
                            {articleItem.totalOutQuantity}
                        </td>
                        
                    </tr>
                   
                    <tr>
                        <td id='title'>Remaining Quantity</td>
                        <td>
                           {articleItem.remainingQuantity}
                        </td>
                        <td id='title'>Remarks</td>
                       <td>{articleItem.remarks}</td>
                    </tr>
                </tbody>
            </table>
            <button className='block' onClick={() => navigate('edit')}>Edit Details</button>
            </div>
           
           
        </div>
        </div>
        
     );
}


export default ArticleItemDetails;
