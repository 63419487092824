import '../../Styles/AdminHome.css';
import {useState} from 'react';
import {useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
// import '../Styles/PurchaseOrder.css'
import api from '../../../contexts/BaseUrl';



function PurchaseOrder(){

    if(localStorage.getItem('auth') == null){
        window.location.reload();
    }
    let params = useParams();
    var buyerName = params.buyerName;
    const navigate = useNavigate();
    

    
    const [filteredPurchaseOrder, setFilteredPurchaseOrder] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    const getBuyerPurchaseOrder = async () => {
        const {data} = await api.get(`api/buyers/get-individual-buyer/${buyerName}`);
        return data;
    }

    const {data} = useQuery("buyerPurchaseOrder", getBuyerPurchaseOrder);
    let buyer = data;
    var purchaseOrder = buyer.purchaseOrders;
   

    return (
         <div>
            <div className='leftBar'>      
               .
            </div>
            <div className='rightBar'>
                <div className=''>
                       .
                </div>
            </div>
            <div className='allUsers'>
                <div>
                   
                  <ul>
                  <h1>{buyer.buyerName} Purchase Orders</h1>
                 {purchaseOrder.map((reptile, key) => (   
                       <li key={key} onClick={() => navigate(`${reptile.id}`)}>{reptile.poNumber}</li>
                       ))}
                 </ul>
                </div>
            </div>
 
        </div>
    )
}

export default PurchaseOrder;