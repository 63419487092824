import api from '../contexts/BaseUrl';
import {useQuery} from 'react-query';
import Card from '../components/Card';
import './Styles/AdminHome.css';
import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import CustomButton from '../components/CustomButton';


const AdminHome = () => {

    const navigate = useNavigate();

    const [allData, setAllData] = useState([]);
    const [filteredData,setFilteredData] = useState(allData);
    const [searchInput, setSearchInput] = useState('');

    if(localStorage.getItem('auth') == null){
        window.location.reload();
    }

    useEffect(() => {
        api.get('api/accounts/all-users')
        .then(response => {
            setAllData(response.data)
            setFilteredData(response.data);
        }).catch(err => {
            console.log("Getting data failed");
            },
        )}, []);

    const handleSearch = (event) => {
        setSearchInput(event.target.value.toLowerCase());
        let result =[];
        result = allData.filter((m) => {
            return m.user.displayName.search(searchInput) !== -1 || m.user.email.toLowerCase().search(searchInput) !== -1;
            // return Object.values(data).join('').includes(searchInput);
        });
        setFilteredData(result);
    }

    return ( 
        <div>
            <div className='leftBar'>
                <h1>Admin Home Page</h1>
            </div>
            <div className='rightBar'>
                <div className=''>
                    {/* <button>Delete Worker</button>
                    <button>Edit Worker</button>
                    <button onClick={() => navigate('/hr-staff/add-worker')}>Add Worker</button> */}
                    <CustomButton
                        title='Add New User'
                        onClick={() => navigate('register')}
                    />
                    
                    <CustomButton
                        title='Delete User'
                        onClick={() => console.log('Worker added')}
                    />
                    
                </div>
            </div>
            <div className='allUsers'>
                <div className='allUsersContent'>
                    <input 
                        type="text"
                        className='searchBar'
                        placeholder="Search by Name or Email"
                        onChange={event => {handleSearch(event)}}  
                    />
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Change Role</th>
                            </tr>
                        </thead>
                        { searchInput.length > 1 ?
                            filteredData.map ((a, key) => {
                                return (
                                <tbody key={key}>
                                <tr>
                                        <td>{a.user.displayName}</td>
                                        <td>{a.user.email}</td>
                                        <td>{a.roles}</td>
                                        <td><button onClick={() => navigate(`${a.user.id}`)}>Change Role</button></td>
                                </tr>
                                </tbody>
                                )
                            }) : 
                            allData.map ((a, key) => {
                                return (
                                <tbody key={key}>
                                <tr>
                                        <td>{a.user.displayName}</td>
                                        <td>{a.user.email}</td>
                                        <td>{a.roles}</td>
                                        <td><button onClick={() => navigate(`${a.user.id}`)}>Change Role</button></td>
                                </tr>
                                </tbody>
                                )
                            })
                        }

                    </table>
                </div> 
            </div>
 
        </div>
     );
}
 
export default AdminHome;