import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import '../Styles/Attendance.css';
import '../Styles/RosterDetails.css';
import CustomButton from '../../components/CustomButton';
import api from '../../contexts/BaseUrl';
import Loading from '../../components/Loading';
import axios from 'axios';


export default function Attendance (){

    const [searchInput, setSearchInput] = useState('');
    const [searchDate, setSearchDate] = useState('');
    const [page, setPage] = useState(1);

    const [pagination, setPagination] = useState([]);
    const [attendance, setAttendances] = useState([]);

    const [success, setSuccess] = useState(null);
    const [err, setErrMsg] = useState('');
    const [errExport, setErrExportMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
   
    const [importExcel, setImportExcel] = useState(false);
    const [exportExcel, setExportExcel] = useState(false);
    const [showAttendance, setShowAttendance] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
    
    const handleImportExcel = () => {
        setImportExcel(!importExcel);
        setShowAttendance(!showAttendance);
    }

    const handleExportExcel = () => {
        setExportExcel(!exportExcel);
        setShowAttendance(!showAttendance);
    }

    const handleCancel = () => {
        setShowAttendance(true);
        setExportExcel(false);
        setImportExcel(false);
        setErrMsg('');
        setErrExportMsg('');
    }

    const navigate = useNavigate();

    const changeParam = () => {
        if(pagination.totalPages > pagination.currentPage){
             setPage(page+1);
        }    
     };
    const changeParamBack = () => {
         if(page>1){
             setPage(page-1);
         }
     };
 
     const handleChange = (event) => {
         if(event.target.value > pagination.totalPages){
             var num = pagination.totalPages;
             setPage(num);
         }else{
             setPage(event.target.value)
         }   
         
     }

    var urlsParams;

    if(searchDate.length > 0 && searchInput.length === 0){
        urlsParams = new URLSearchParams({
            checkDate: searchDate
        }).toString();
    }else if(searchInput.length>0 && searchDate.length === 0 ){
        urlsParams = new URLSearchParams({
            checkUser: searchInput
        }).toString();
    }else if(searchDate.length > 0 && searchInput.length > 0){
        urlsParams = new URLSearchParams({
            checkUser: searchInput,
            checkDate: searchDate
        }).toString();
    }else{
        urlsParams = new URLSearchParams({
            PageNumber: page
        }).toString();
    }

    const url = "api/attendances/get-attendances?" + urlsParams;

    useEffect(() => {
        setIsLoading(true);
        api.get(url)
        .then(response => {
            setAttendances(response.data);
            setIsLoading(false);
            setPagination(JSON.parse(response.headers.pagination));
        }).catch(err => {
            console.log("Getting data failed");
            setIsLoading(false);
            },
        )}, [url]);    


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData();
        formData.append("file", selectedFile); 
        try {
            const response = await api.post('api/attendances/import-excel', 
            {
                postedFile: selectedFile
            },
            {
                headers: {
                    "Content-Type": "multipart/form-data" 
                }
            });  
            console.log(response);
            handleOverTimeEarlyLeave();
            setIsLoading(false);
            handleCancel();
           
        }catch(err){
            setSuccess(false);
            setIsLoading(false);
            if(!err?.response){
                setErrMsg('No Server Response')
            }else{
                setErrMsg('Failed Uploading!');
            }
        }
    } 
    var urlExport;
    if(searchDate.length>4){
        urlExport = `http://localhost:5211/api/attendances/export-excel?date=${searchDate}`
    }else{
        urlExport = `http://localhost:5211/api/attendances/export-excel`
    }
    const handleExportSubmit = () => { 
        setIsLoading(true);  
        try{
            axios.get(urlExport,{
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]), {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Attendance.xlsx');
                document.body.appendChild(link);
                link.click();
                setIsLoading(false);
            });
            setIsLoading(false);
           
        }catch(err){
            setSuccess(false);
            setIsLoading(false);
            if(!err?.response){
                setErrExportMsg('No Server Response')
            }else{
                setErrExportMsg('Failed Exporting!');
            } 
        }
    }

    const handleOverTimeEarlyLeave = async (e) => {
        
        setIsLoading(true);
        try{
           const response = await api.get('api/attendances/get-overtime', {
                headers: {
                    "Content-Type" : "application/json"
                }
            })

            console.log(response);
            setIsLoading(false);
            navigate(-1);
        }catch(err){
            console.log("Failed Updating");
        }
        
    }

    return(
        <div>
            <div className='leftBar'>
                <CustomButton
                    title='Home'
                    onClick={() => navigate('/hr-staff')}
                />
                <button onClick={() => console.log(selectedFile.size)}>See File</button> 
            </div>
            <div className='rightBar'>
                {importExcel === false &&    
                    <CustomButton
                        title='Download Excel'
                        onClick={handleExportSubmit}
                    />
                }   
               {exportExcel === false &&
                    <CustomButton
                        title='Upload Excel'
                        onClick={handleImportExcel}
                    />
               }
               <CustomButton
                    title="Update Over Time/Early Leave"
                    onClick={handleOverTimeEarlyLeave}
               />
               <CustomButton
                    title='Add Attendance'
                    onClick={() => navigate('/hr-staff/add-attendance')}
               />
             
            </div>
            <div className="workers">
               {showAttendance && 
                <div className="workersContent">
                     
                    <div className='heading'>Attendance Details</div>
                    <div className='heading'>
                        <input
                            type='date' 
                            className='inputDate'
                            value={searchDate}
                            onChange={(event) => setSearchDate(event.target.value)}
                        />
                    </div>
                        
                        <input 
                            type="text"
                            className='searchBar'
                            value = {searchInput}
                            placeholder="Search by Employee ID"
                            onChange={(event) => setSearchInput(event.target.value) } 
                        />
                        
                    
                        {isLoading ? <Loading /> :
                        <table>
                            <thead>
                                <tr>
                                    <th>Employee ID</th>
                                    <th>Date</th>
                                    <th>In Time</th>
                                    <th>Out Time</th>
                                    <th>Over Time (Min)</th>
                                    <th>Early Leave (Min)</th>
                                    <th>Late Day</th>
                                    <th>Details</th>
                                </tr>
                            </thead>
                            {attendance.map((a, key) => {
                                return(
                                    <tbody key={key}>
                                        <tr>
                                            <td>{a.userId}</td>
                                            <td>{a.date}</td>
                                            <td>{a.timeIn}</td>
                                            <td>{a.timeOut}</td>
                                            <td>{a.overTime}</td>
                                            <td>{a.earlyLeave}</td>
                                            <td>{a.lateDays}</td>
                                            <td><button onClick={() => navigate(`${a.id}`)}>Edit</button></td>
                                        </tr>
                                    </tbody>
                                )
                            })}
                            
                        </table>}
                        <div className='changePageFooter'>
                            <button id='backButton' onClick={changeParamBack}>Back</button>
                            <input
                                id='inputFooter'
                                value={page}
                                onChange={handleChange}
                            />
                            <span id='totalPage'> / {pagination.totalPages}</span>
                            <button id='nextButton' onClick={changeParam}>Next</button>
                        </div>
                    </div>}

                    {importExcel && 
                        <form className='uploadContent' onSubmit={handleSubmit}>
                            <div className='titleImport'>
                                Import Excel File 
                            </div>
                            <div className='chooseFile'>
                                <input 
                                    type='file' 
                                    required 
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onChange={e  => setSelectedFile(e.target.files[0])}
                                />
                            </div>
                            <div id='upload'>
                                {isLoading ? <span>Loading...</span> : <button>Upload Excel</button>}
                            </div>

                             <div id='cancel'>
                                <button onClick={handleCancel}>Cancel</button>
                            </div>
                               
                            <div className='succErr'>
                               
                            {!success && <div className='error'>{err} </div>}
                            </div>        
                        </form>    
                    }

                    {exportExcel &&
                        <div className='uploadContent'>
                            <div className="titleImport">
                                Export Excel File
                            </div>
                            <div className="chooseFile">
                                Are you sure you want to export excel file?
                            </div>
                            <div id='upload'>
                                {isLoading ? <span>Loading...</span> : <button onClick={handleExportSubmit}>YES</button>}
                            </div>
                            <div id='cancel'>
                                <button onClick={handleCancel}>NO</button>
                            </div>        
                            <div className='succErr'>    
                            {!success && <div className='error'>{errExport}</div>}
                            </div>
                        </div>
                    }
                      
                </div>
              
         
        </div>
    );
}