import { useContext, useState } from 'react';
import {useQuery} from 'react-query';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import AuthContext from '../contexts/AuthProvider';
import api from '../contexts/BaseUrl';
import './Styles/UserDetails.css';
import Card from '../components/Card';
//import getUserDetails from './fetch/getUserDetails';

function UserDetails(){

    let navigate = useNavigate();

    const {auth} = useContext(AuthContext);

    const user = localStorage.getItem('auth');
    const userObj = JSON.parse(user);

    const getUserDetails = async () => {
        const {data} = await api.get('/api/accounts',{
            headers:{Authorization: `Bearer ${userObj.token}` }
        });
        return data;
    }

    const {data} = useQuery("userDetails", getUserDetails);

   
    var tokenGet = `${auth.email}`;
   // const user = JSON.parse(JSON.stringify(data));



    return ( 
      
         <div>
            

            <div className='userContent'>
                <Card 
                    email = {data.email}
                    name = {data.displayName}
                    role={data.role}
                    button = 'true'
                    buttonTitle='Change Password'
                    onClick={() => navigate('change-password')}
                 />
            </div> 
             
            
        </div>
        
     );
}
 
export default UserDetails;