import {  useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import api from '../contexts/BaseUrl';
//import getUserDetails from './fetch/getUserDetails';

function ResetPassword(){


    const [searchParams] = useSearchParams();

 
   
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [success, setSuccess] = useState(null);

    const navigate = useNavigate();

    const [err, setErrMsg] = useState("")

 
   // const user = JSON.parse(JSON.stringify(data));

   const email = searchParams.get('email');
   const token = searchParams.get('token');

   var tokenGet = `Bearer ${token}`;

   

   const handleSubmit = async (e) => {

        e.preventDefault();
        try{
            const response = await api.post(
                '/api/accounts/reset-password', 
               {
                   password: password,
                   confirmPassword: confirmPassword,
                   token: token,
                   email: email
               },
                {
                    headers:{
                        "Accept" : "application/json",
                        "Content-Type" : "application/json",
                    }
                });
         
            setPassword("");
            setConfirmPassword("");
            setSuccess(true);
           
        }catch(err){
           
            setPassword("");
            setConfirmPassword("");
            setSuccess(false);
            if(!err?.response){
                setErrMsg('No Server Response')
            }else{
                setErrMsg('Failed changing password!');
            }
        }
        
   }


    return ( 
      
         <div>
             <div>Email : {email}</div>
             <button onClick={() => console.log(tokenGet)}>Token</button>
             
                    <form className="userContent2" 
                        onSubmit={handleSubmit}
                    >
                    

                    <label>New Password: </label>
                    <input
                        type="password"
                        pattern = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                        value = {password}
                        required
                        onChange={event => {
                            setPassword(event.target.value);
                         
                            if (event.target?.validity?.patternMismatch) {
                                event.target?.setCustomValidity('Min - 8 chars,1 Uppercase, 1 Lowercase, 1 number, 1 special character ');
                            } else {
                                event.target?.setCustomValidity('');
                            }
                        }}
                    />
                    <label>Confirm New Password: </label>
                    <input
                        type="password"
                        pattern = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                        value = {confirmPassword}
                        required
                        onChange={event => {
                            setConfirmPassword(event.target.value);
                           
                            if (event.target?.value !== password) {
                                event.target?.setCustomValidity('Password doesn\'t match');
                            } else {
                                event.target?.setCustomValidity('');
                            }
                        }}
                    />
                    <button className='userButton'>Save</button>

                    {!success && <div className='error'>{err}</div>}
                    {success && <div className='success'>Password Successfully Changed!</div>}
                </form>
            
        </div>
        
     );
}
 
export default ResetPassword;