import {Link, Navigate, Routes, Route, useNavigate} from 'react-router-dom';
import {useContext, useState} from 'react';
import AuthContext from '../contexts/AuthProvider';

import Home from '../pages/Home';
import UserDetails from '../pages/UserDetails';

const Navbar = () => {

    const navigate = useNavigate();
    const {auth, setAuth} = useContext(AuthContext);
    const {show, setShow} = useState(false);

    // const user = JSON.stringify(auth) === '{}';
    const user = localStorage.getItem('auth');
    const userObj = JSON.parse(user);

    const handleClick = () => {
        // setAuth({});
        localStorage.removeItem('auth');  
        navigate('/'); 
    }

    

    return ( 
        
        <nav className="navbar">
            <h1 onClick={() => navigate('/')}>BLING  Staff  Portal</h1>

                {/* {user == null &&
                    <div className="links">
                        <Link to="/">Login </Link>
                        
                    </div>
                } */}

                {(user != null && userObj.role === 'HRStaff') &&
                    <div className="links">
                        <Link to = 'hr-staff/user-details'>{userObj.displayName}</Link>
                        <button onClick={handleClick}>Logout</button>
                    </div>
                }

                 {(user != null && userObj.role === 'Admin') &&
                    <div className="links">
                        <Link to = '/admin/user-details'>{userObj.displayName}</Link>
                        <button onClick={handleClick}>Logout</button>
                    </div>
                }

                 {(user != null && userObj.role === 'StoreManager') &&
                    <div className="links">
                        <Link to = '/store/user-details'>{userObj.displayName}</Link>
                        <button onClick={handleClick}>Logout</button>
                    </div>
                }

                {(user != null && userObj.role === 'Director') &&
                    <div className="links">
                        <Link to = '/director/user-details'>{userObj.displayName}</Link>
                        <button onClick={handleClick}>Logout</button>
                    </div>
                }
           
        </nav>
       
     );
}
 
export default Navbar;