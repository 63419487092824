import { useContext, useState } from 'react';
import {useQuery} from 'react-query';
import { Navigate, useNavigate } from 'react-router-dom';
import AuthContext from '../contexts/AuthProvider';
import api from '../contexts/BaseUrl';
import Loader from '../components/Loader';
//import getUserDetails from './fetch/getUserDetails';

function ChangePassword(){

    let navigate = useNavigate();

    // const {auth} = useContext(AuthContext);
   
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [success, setSuccess] = useState(null);
    const [isLoading, setIsLoading] = useState(false);



    const [err, setErrMsg] = useState("")

    const user = localStorage.getItem('auth');
    const userObj = JSON.parse(user);

   
    // var tokenGet = `${auth.email}`;
   // const user = JSON.parse(JSON.stringify(data));

   const handleSubmit = async (e) => {
        const email = `${userObj.email}`;
        e.preventDefault();
        setIsLoading(true);
        try{
            const response = await api.post('/api/accounts/change-password', 
            JSON.stringify({email, currentPassword, newPassword}),
            {
                headers:{
                    "Accept" : "application/json",
                    "Content-Type" : "application/json",
                    "Authorization": `Bearer ${userObj.token}`
                }
            });
            
            setCurrentPassword('');
            setNewPassword("");
            setConfirmPassword("");
            setIsLoading(false);
            setSuccess(true);
        }catch(err){
            setCurrentPassword('');
            setNewPassword("");
            setConfirmPassword("");
            setSuccess(false);
            setIsLoading(false);
            if(!err?.response){
                setErrMsg('No Server Response')
            }else{
                setErrMsg('Failed changing password!');
            }
        }
        
   }


    return ( 
      
         <div>
             
                    <form className="userContent2" 
                        onSubmit={handleSubmit}
                    >
                    <label>Current Password: </label>
                    <input
                        type="password"
                        value = {currentPassword}
                        required
                        onChange={event => {
                            setCurrentPassword(event.target.value);
                        }}
                    />

                    <label>New Password: </label>
                    <input
                        type="password"
                        value = {newPassword}
                        required
                        onChange={event => {
                            setNewPassword(event.target.value);
                        }}
                    />
                    <label>Confirm New Password: </label>
                    <input
                        type="password"
                        value = {confirmPassword}
                        required
                        onChange={event => {
                            setConfirmPassword(event.target.value);
                           
                            if (event.target?.value !== newPassword) {
                                event.target?.setCustomValidity('Password doesn\'t match');
                            } else {
                                event.target?.setCustomValidity('');
                            }
                        }}
                    />
                    {isLoading ? <Loader /> : <button className='userButton'>Save</button>}

                    {!success && <div className='error'>{err}</div>}
                    {success && <div className='success'>Password Successfully Changed!</div>}
                </form>
            
        </div>
        
     );
}
 
export default ChangePassword;