import {useState, useEffect} from 'react';
import {useQuery} from 'react-query';
import {useParams, useNavigate} from 'react-router-dom';
import api from '../../../contexts/BaseUrl';
import '../../Styles/PurchaseOrderArticle.css';
import axios from 'axios';

function PurchaseOrderArticle(){

    const navigate = useNavigate();
    const params = useParams();

    const [articles, setArticles] = useState([]);
    const [articleId, setArticleId] = useState(0);

    

    const getPurchaseArticles = async () => {
        const {data} = await api.get(`api/purchaseorders/get-individual-po/${params.purchaseOrderId}`);
        return data;
    }

    const {data} = useQuery("buyerPurchaseArticle", getPurchaseArticles);
    let buyer = data;

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [err, setErrExportMsg] = useState('');

    useEffect(() => {
        if(articleId !== 0){
            api.get(`api/articles/get-individual-article/${articleId}`)
            .then(response => {
                setArticles(response.data);        
            }).catch(err => {
                console.log("Fetching article failed")
                }
            )
        }
       }, [articleId])
    

    return(
        <div>
            <div className='leftBar'>
                <h2 style={{ textAlign: 'center' }}>Article Number</h2>
                <table className='buyerName'>
                    {buyer.articles.map((a, key) => {
                        return(
                            <tbody key={key}>
                                <tr onClick={() => setArticleId(a.id)}>
                                    <td>{a.articleNumber}</td>
                                </tr>
                            </tbody>
                        )
                    })}
                </table>
            </div>
            <div className='rightBar'>
                  .
            </div>

            <div className='allUsers'>
                {articles.articleItems == null &&<h1>{params.buyerName} - {buyer.poNumber} </h1>}
                {articles.articleItems != null &&
                    <div className='orderItemAll'> 
                        <h1>{params.buyerName} - {buyer.poNumber} - {articles.articleNumber}</h1>
                        <table>
                            <thead>
                                <tr>
                                    <th>Item Name</th>       
                                    <th>Item Color</th>
                                    <th>Item Unit</th>
                                    <th>Total Received Quantity</th>
                                    <th>Total Out Quantity</th>
                                    <th>Remaining Quantity</th>
                                    {/* <th>Edit</th> */}
                                   
                                </tr>         
                            </thead>
                            {articles.articleItems.map((a, key) => {
                                return(
                                    <tbody key={key}>
                                        <tr>
                                            <td>{a.itemName}</td>
                                            <td>{a.itemColor}</td>
                                            <td>{a.itemUnit}</td>
                                            <td>{a.totalReceivedQuantity}</td>
                                            <td>{a.totalOutQuantity}</td>
                                            <td>{a.remainingQuantity}</td>
                                            
                                           
                                        </tr>
                                    </tbody>
                                )
                            })}
                        </table>
                       
                    </div>
                }
            </div>
        </div>
    )
}

export default PurchaseOrderArticle;