import {useState} from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import {useQuery} from 'react-query';
import Loader from '../../components/Loader';
import '../Styles/Worker.css';
import '../Styles/AddWorker.css';
import api from '../../contexts/BaseUrl';



function UpdateWorker () {

    let params = useParams();
    var workerId = params.userId;

    const getWorkerInfo = async () => {
        const {data} = await api.get(`/api/workers/get-worker/${workerId}`)
        return data;
    }
    const {data} = useQuery("userInfo", getWorkerInfo);
    let worker = data;

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const [userId, setUserId] = useState(worker.userId);
    const [name, setName] = useState(worker.name)
    const [phoneNumber, setPhoneNumber] =useState(worker.phoneNumber);
    const [dateOfJoining, setDateOfJoining] = useState(worker.dateOfJoining);
    const [department, setDepartment] = useState(worker.department);
    const [lengthOfService, setLengthOfService] = useState(worker.lengthOfService);
    const [dateOfPermanent, setDateOfPermanent] = useState(worker.dateOfPermanent);
    const [designation, setDesignation] = useState(worker.designation);
    const [subDesignation, setSubDesignation] = useState(worker.subDesignation);
    const [section, setSection] = useState(worker.section);
    const [subSection, setSubSection] = useState(worker.subSection);
    const [line, setLine] = useState(worker.line);
    const [shift, setShift] = useState(worker.shift);
    const [gender, setGender] = useState(worker.gender);
    const [employmentStatus, setEmploymentStatus] = useState(worker.employmentStatus);
    const [nid, setNid] = useState(worker.nid);
    const [presentAddress, setPresentAddress] = useState(worker.presentAddress);
    const [permanentAddress, setPermanentAddress] = useState(worker.permanentAddress);
    const [fatherHusbandName, setFatherHusbandName] = useState(worker.fatherHusbandName);
    const [motherName, setMotherName] = useState(worker.motherName);
    const [birthDate, setBirthDate] = useState(worker.birthDate);
    const [age, setAge] = useState(worker.age);
    const [maritalStatus, setMaritalStatus] = useState(worker.maritalStatus);
    const [religion, setReligion] = useState(worker.religion);
    const [bloodGroup, setBloodGroup] = useState(worker.bloodGroup);
    


    const [success, setSuccess] = useState(null);
    const [err, setErrMsg] = useState('');

    const handleSubmit =  async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try{
            const response = await api.post(`api/workers/${worker.userId}`,
                {
                  userId : userId,
                  name: name,
                  phoneNumber: phoneNumber,
                  dateOfJoining: dateOfJoining,
                  dateOfPermanent: dateOfPermanent,
                  lengthOfService:lengthOfService,
                  department: department,
                  designation: designation,
                  subDesignation: subDesignation,
                  section: section,
                  subSection: subSection,
                  line: line,
                  shift: shift,
                  gender: gender,
                  employmentStatus: employmentStatus,
                  nid: nid,
                  presentAddress: presentAddress,
                  permanentAddress: permanentAddress,
                  age: age,
                  maritalStatus: maritalStatus,
                  religion: religion,
                  bloodGroup: bloodGroup,
                  fatherHusbandName: fatherHusbandName,
                  motherName: motherName,
                  birthDate: birthDate, 
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin" : "*",
                        "Content-Type" : "application/json"
                    }
                });

            setSuccess(true);
            setIsLoading(false);
            navigate(-1);
            
        }catch(err){

            setSuccess(false);
            setIsLoading(false);
            if(!err?.response){
                setErrMsg('No Server Response')
            }else{
                setErrMsg('Failed Updating!');
            }
        }
    }
    
    return ( 
        <div>
            <div className='heading'>Employee Details</div>
  
            <form className='addWorkerTable' onSubmit={handleSubmit}>
            <table id='workerTable'>
                <tbody>
                    <tr>
                        <td>Name*</td>
                        <td>
                            <input  
                                type='text'
                                required
                                value={name}
                                onChange={event => {
                                    setName(event.target.value);
                                }}
                            />
                        </td>
                        <td>Employee ID*</td>
                        <td>
                           {worker.userId}
                        </td>
                    </tr>
                    <tr>
                        <td>Phone Number*</td>
                        <td>
                            <input  
                                type='number'
                                required
                                value={phoneNumber}
                                onChange={event => {
                                    setPhoneNumber(event.target.value);
                                }}
                            />
                        </td>
                        <td>Joining Date*</td>
                        <td>
                            <input  
                                type='date'
                                required
                                value={dateOfJoining}
                                onChange={event => {
                                    setDateOfJoining(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Service Length</td>
                        <td>
                            <input  
                                type='text'
                                value={lengthOfService}
                                onChange={event => {
                                    setLengthOfService(event.target.value);
                                }}
                            />
                        </td>
                        <td>Permanent Date</td>
                        <td>
                            <input  
                                type='date'
                                value={dateOfPermanent}
                                onChange={event => {
                                    setDateOfPermanent(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Designation</td>
                        <td>
                            <input  
                                type='text'
                                value={designation}
                                onChange={event => {
                                    setDesignation(event.target.value);
                                }}
                            />
                        </td>
                        <td>Sub Designation</td>
                        <td>
                            <input  
                                type='text'
                                value={subDesignation}
                                onChange={event => {
                                    setSubDesignation(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Department</td>
                        <td>
                            <input 
                                type='text'
                                value={department}
                                onChange={event => {
                                    setDepartment(event.target.value);
                                }}
                            />
                        </td>     
                    </tr>
                    <tr>
                        <td>Section</td>
                        <td>
                            <input  
                                type='text'
                                value={section}
                                onChange={event => {
                                    setSection(event.target.value);
                                }}
                            />
                        </td>
                        <td>Sub Section</td>
                        <td>
                            <input  
                                type='text'
                                value={subSection}
                                onChange={event => {
                                    setSubSection(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Line</td>
                        <td>
                            <input  
                                type='text'
                                value={line}
                                onChange={event => {
                                    setLine(event.target.value);
                                }}
                            />
                        </td>
                        <td>Shift</td>
                        <td>
                            <input  
                                type='text'
                                value={shift}
                                onChange={event => {
                                    setShift(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Gender</td>
                        <td>
                            <input  
                                type='text'
                                value={gender}
                                onChange={event => {
                                    setGender(event.target.value);
                                }}
                            />
                        </td>
                        <td>Employment Status</td>
                        <td>
                            <input  
                                type='text'
                                value={employmentStatus}
                                onChange={event => {
                                    setEmploymentStatus(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>NID/Birth Rego</td>
                        <td>
                            <input  
                                type='text'
                                value={nid}
                                onChange={event => {
                                    setNid(event.target.value);
                                }}
                            />
                        </td>
                        <td>Age</td>
                        <td>
                            <input  
                                type='text'
                                value={age}
                                onChange={event => {
                                    setAge(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Father/Husband Name</td>
                        <td>
                            <input  
                                type='text'
                                value={fatherHusbandName}
                                onChange={event => {
                                    setFatherHusbandName(event.target.value);
                                }}
                            />
                        </td>
                        <td>Mother's Name</td>
                        <td>
                            <input  
                                type='text'
                                value={motherName}
                                onChange={event => {
                                    setMotherName(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Religion</td>
                        <td>
                            <input  
                                type='text'
                                value={religion}
                                onChange={event => {
                                    setReligion(event.target.value);
                                }}
                            />
                        </td>
                        <td>Marital Status</td>
                        <td>
                            <input  
                                type='text'
                                value={maritalStatus}
                                onChange={event => {
                                    setMaritalStatus(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Blood Group</td>
                        <td>
                            <input  
                                type='text'
                                value={bloodGroup}
                                onChange={event => {
                                    setBloodGroup(event.target.value);
                                }}
                            />
                        </td>
                        <td>Birth Date</td>
                        <td>
                            <input 
                                type='date'
                                value={birthDate}
                                onChange={event => {
                                    setBirthDate(event.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                    <td>Present Address</td>
                        <td>
                            <textarea 
                                value={presentAddress}
                                onChange={event => {
                                    setPresentAddress(event.target.value);
                                }}
                            />
                        </td>
                        <td>Permanent Address</td>
                        <td>
                            <textarea 
                                value={permanentAddress}
                                onChange={event => {
                                    setPermanentAddress(event.target.value);
                                }}
                            />
                        </td>
                    
                    </tr>
                </tbody>
            </table>
            {isLoading ? <Loader /> : <button className='block'>Update Employee Details</button>}
           
            {!success && <div className='error'>{err} </div>}
            </form>
    
           
        </div>
        
     );
}


export default UpdateWorker;



