import {useEffect, useState, useQuery} from 'react';
import { useNavigate } from "react-router-dom"
import '../Styles/MainHome.css';





function DirectorHome() {

    const navigate = useNavigate();

    const [allData, setAllData] = useState([]);
    const [pagination, setPagination] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);

   
    if(localStorage.getItem('auth') == null){
        window.location.reload();
    }

    
    

    return ( 
        
        <div>  
            <div className='leftBar'>
                <span>.</span>
            </div>
            <div className='rightBar'>
                   <span>.</span>
            </div>
            
            <div className="mainContent">
                
                    <span className='blockSection' onClick={() => navigate('store')}>
                        Store  
                    </span>    
                    {/* <span className='blockSection' onClick={() => navigate('roster')}>
                        Rostering 
                    </span>           
                    <span className='blockSection' onClick={() => navigate('attendance')}>
                        Attendance  
                    </span> 
                    <span className='blockSection' onClick={() => navigate('payroll')}>
                        Payroll  
                    </span> */}
                
                   
            </div>
        </div>
        
     );
}
 
export default DirectorHome;

